<template>

  <div class="pd-16 is-flex align-items-center header-menu">
    <div class='header-content'>
      <div class='columns no-margin'>

        <div class="column is-flex align-items-center is-8 no-padding">
          <div class="mr-16">
            <img
              src="~@/assets/img_logo.png"
              width="64"
              height="64"
              alt="logo"
            />
          </div>
          <h1 class="font-size-32 font-size-24-mobile">Savanna Kidz NFT</h1>

          <primary-button
            class="ml-16"
            :buttonText="'Buy an NFT'"
            @click="open"
          />
        </div>

        <div class="column is-flex justify-content-end no-padding align-items-center">
          <a class="ml-32" :href="docsUrl" target="_blank">
            <img
              src="~@/assets/ic_document.png"
              width="48"
              height="48"
              alt="document icon"
            />
          </a>

          <a
            class="ml-32"
            href="https://twitter.com/savannakidz "
            target="_blank"
          >
            <img
              src="~@/assets/ic_twitter.png"
              width="48"
              height="48"
              alt="twitter logo"
            />
          </a>

          <a
            class="ml-32"
            href="https://discord.gg/tGR4GwnS9S"
            target="_blank"
          >
            <img
              src="~@/assets/ic_discord.png"
              width="48"
              height="48"
              alt="discord logo"
            />
          </a>

          <a
            class="ml-32"
            href="https://opensea.io/collection/savanna-kidz"
            target="_blank"
          >
            <img
              src="~@/assets/ic_opensea.png"
              width="48"
              height="48"
              alt="opensea logo"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "./PrimaryButton.vue";
import { useDocuments } from "../logic/useDocuments";
import { useMinter } from '../logic/useMinter'

export default {
  components: {
    PrimaryButton,
  },
  setup(){
    const { docsUrl } = useDocuments();
    const { open } = useMinter();

    return {
      docsUrl,
      open,
    }
  }
};

</script>

<style lang="scss" scoped>
.header-menu {
  height: 100px;
  position: relative;
  z-index: $menu-lay;
  background-color: $yellow;
  border-bottom: 4px solid #000000;
  margin-bottom: 0;
  justify-content: center;
}

.header-content {
  width: 100%;
  max-width: 1080px;
}
</style>
