<template>
  <div :class="isLoading ? 'is-disabled' : ''">
    <div class="tags has-addons">
      <span class="tag is-medium">Total supply</span>
      <span class="tag is-medium is-primary is-light">{{
        ethState.skSupply
      }}</span>
    </div>

    <div class="tags has-addons">
      <span class="tag">Address</span>
      <span class="tag is-info is-light">{{
        ethState.skContract.options.address
      }}</span>
    </div>

    <div class="tags has-addons">
      <span class="tag">OpenTown</span>
      <span class="tag is-primary is-light">{{
        ethState.skCurrentOTAddress
      }}</span>
      <a @click="updateOT" class="ml-4 is-size-7">Update</a>
    </div>
  </div>
</template>

<script>
import {
  state,
  getSKSupply,
  getCurrentOTAddress,
  updateOTAddress,
} from "../../utils/ethereum";

export default {
  name: "SKMain",
  data: () => ({
    isLoading: true,
    ethState: state,
  }),
  methods: {
    async fetchContractData() {
      this.isLoading = true;
      await getSKSupply();
      await getCurrentOTAddress();
      this.isLoading = false;
    },
    async updateOT() {
      this.isLoading = true;
      await updateOTAddress();
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.fetchContractData();
  },
};
</script>
