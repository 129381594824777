<template>
  <div class="mt-32">
    <div class="has-text-centered">
      <img :src="member.img" height="180" width="180" alt="collection" />
    </div>
    <p class="font-size-16 mt-32 has-text-centered">
      {{ member.role }}
    </p>
    <div class="has-text-centered mt-16">
      <span class="font-size-24">
        {{ member.name }}
      </span>
    </div>
    <p class="font-size-16 mt-16">
      {{ member.desc }}
    </p>
  </div>
</template>

<script>

export default {
  name: "Member",
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
};

</script>
