<template>
  <div class="light-green-bg-color section-height">
    <div class="common-section-container">
      <h3 class="font-size-48-pc font-size-36">FAQ</h3>
      <div
        class="mt-32 mt-48-pc"
        v-for="question of questions"
        :key="question.index"
      >
        <div>
          <p class="font-size-24">
            {{ question.title }}
          </p>
        </div>
        <div>
          <p class="font-size-16 mt-16 has-new-line ml-16">
            {{ question.desc }}
            <a
              class="default-text-color is-underlined has-text-weight-bold"
              :href="howToMintURL"
              target="_blank"
              >{{ question.linkText }}</a
            >
          </p>
        </div>
      </div>
      <div class="mt-32 is-flex justify-content-center">
        <primary-button
          :linkUrl="linkToNotion"
          :buttonIcon="documentIcon"
          :buttonText="documentButtonText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DOCS_URL, TERMS_URL, HOW_TO_MINT } from "../config";
import PrimaryButton from "../components/PrimaryButton.vue";
export default {
  components: { PrimaryButton },
  data() {
    return {
      linkToNotion: DOCS_URL + TERMS_URL,
      documentIcon: require("@/assets/ic_document.png"),
      documentButtonText: "Terms & Conditions",
      howToMintURL: DOCS_URL + HOW_TO_MINT,
      questions: [
        {
          title: "Quick Glance",
          desc: "Supply: 10,000 NFTs\nPurchase Price: 0.05 ETH + gas(Transaction fee)\nPurchase Limit/Buyer: 20 per transaction\nIntellectual Property of NFTs: Open For Holders\nUtility: Voting rights on Open Town project development decissions.",
        },
        {
          title: "$OPENTOWN token",
          desc: "$OPENTOWN is a governance token that represent the voting power on the Open Town DAO initiatives.",
        },
        {
          title: "How will the planning for District XX be conducted?",
          desc: "Holders of Savanna Kidz NFTs will coordinate with key members of Faith Angels Ministry to plan the town development through a series of discussions and proposals. Such proposals will be executed through DAO vote by Savanna Kidz holders. District XX is independent of central government involvement which will instead be wholly administered by DAO officers and the Faith Angels Ministry.",
        },
        {
          title: "What rights do Savanna Kidz NFT holders have?",
          desc: "Voting rights related to urban development\nCommercial rights\nRight to give opinions on the roadmap\nRight to participate in airdrops(receive free items) and other events\nRight to join a dedicated community, together with other holders and community members",
        },
        {
          title: "How can I purchase Savanna Kidz NFTs?",
          desc: "Savanna Kidz NFTs sale is live right now, so you can purchase your NFTs on our website. The NFT will be randomly generated, delivered to your electronic wallet and OpenSea account upon purchase.",
        },
        {
          title: "Price",
          desc: "Each NFT costs 0.05 ETH + gas (transaction fee). You can purchase a maximum of 20 Savanna Kidz per transaction.",
        },
        {
          title: "How to buy",
          desc: "To buy(mint) Savanna Kidz NFT you will need an Ethereum Wallet, Ethereum and connect to the wallet to our website.\n You can follow a detailed guide",
          linkText: "CLICKING HERE",
        },
      ],
    };
  },
};
</script>
