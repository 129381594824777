<template>
  <div v-if="viewDevice == 'desktop'">
    <MapView />
  </div>
</template>

<script>
import MapView from "./Map/MapView.vue";

export default {
  name: "FirstView",
  data: () => ({
    viewDevice: null
  }),
  components: {
    MapView,
  },
  async mounted () {
    if (window.matchMedia && window.matchMedia('(min-width: 1024px)').matches) {
      this.viewDevice = 'desktop'
    } else {
      this.viewDevice = 'mobile'
    }
    window.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('resize', () => {
        if (window.innerWidth > 1024) {
          this.viewDevice = 'desktop'
        } else if (window.innerWidth < 1023)
          this.viewDevice = 'mobile'
      });
    });
  }
};
</script>
