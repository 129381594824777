<template>
  <div class='slide-container' :style="`height: ${height}`">
    <carousel :items-to-show="1" :autoplay="6000" :wrap-around="true">
      <slide v-for="(item, index) in images" :key="index">
        <div class="slide" :style="`height: ${height}`">
          <img :src="item" :style="`height: ${height}`" />
        </div>
      </slide>

      <template #addons>
        <pagination />
      </template>
    </carousel>

    <div class='item-content ml-32 mb-32 mr-32'>
      <slot name='footer' />
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  props: {
    height: {
      type: String,
      default: '100vh',
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

// you can't set scoped for carousel
<style lang="scss">
.carousel__pagination{
  position: absolute;
  right: 0;
  left: 0;
  bottom: 16px;
}
.carousel__pagination-button{
  width: 48px;
  height: 8px;
}
.carousel__pagination-button::after{
  width: 42px;
  height: 8px;
  background-color: #fff;
}
.carousel__pagination-button--active::after {
  background-color: $yellow !important;
}

</style>

<style lang="scss" scoped>
.slide-container{
  position: relative;
  width: 100%;
}
.item-content{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
}

.slide{
  width: 100%;
  position: relative;
  overflow: hidden;
  img{
    width: 100%;
    object-fit: cover;
  }
}

</style>
