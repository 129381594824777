<template>
  <div class='background pd-48-pc'>
    <div class="common-section-container">
      <h3 class='font-size-48 font-size-36-mobile bold mb-32'>
        Autonomous community development by villagers
      </h3>
      <div class='font-size-24 font-size-16-mobile mb-16'>
        The residents will autonomously decide what equipment to build with the proceeds from the NFT art sales. They put together a video of several proposals and invited NFT holders to vote on them.
      </div>
      <div class='font-size-24 font-size-16-mobile mb-16 bold'>
        Proposal for 2nd project (vote June 2022)
      </div>

      <!-- Proposals loop though data items to render -->
      <div v-for='(item, index) in items' :key='index' >

        <div class='proposal pd-24 pd-16-mobile mb-24 mb-16-mobile'>
          <div class='columns'>
            <div class='proposal-cell column'>
              <div class='font-size-24 font-size-16-mobile bold mb-16'>
                {{ item.title }}
              </div>
              <img :src="item.image" alt="" class='item-image'/>
              <div class='font-size-24 font-size-16-mobile'>
                {{ item.description }}
              </div>
            </div>
            <div class='proposal-cell column'>
              <div class='full-width'>
                <Youtube :videoId="item.videoId" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='proposal-footer mt-16'>
        <h3 class='font-size-36 font-size-24-mobile bold'>
          Holders and villagers voted to implement proposal 3.
        </h3>
        <h3 class='font-size-36 font-size-24-mobile bold'>
          Build and Improve the Primary School!
        </h3>
      </div>

    </div>
  </div>
</template>

<script>
import Youtube from '@/components/Youtube.vue'
export default {
  name: 'Proposals',
  components: {
    Youtube
  },
  data () {
    return {
      items: [
        {
          title: 'Proposal 1 Build a Community Bank!',
          description: 'This is not an actual bank but a financial setup to be created for providing small, Interest-Free loans to the Villagers to fund their businesses and other necessities',
          image: require('@/assets/img_bank_128px.png'),
          videoId: 'Y2zSX2GG5sk'
        },

        {
          title: 'Proposal 2 Buy and Install Community Bus',
          description: 'One of the voting options is to provide the village with a bus to facilitate transportation',
          image: require('@/assets/img_community_bus_128px.png'),
          videoId: '5kQbHp9uxpw'
        },

        {
          title: 'Proposal 3 Build and Improve the Primary School ',
          description: 'As one of the voting options, the school\'s development is a clear urgent need for the villagers.',
          image: require('@/assets/img_school_facilities_128px.png'),
          videoId: 'gpOVflN8Cr4'
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.background{
  background-color: $light-blue;
}

.proposal {
  background: #FFFFFF;
  border-radius: 24px;
  min-height: 320px;
}

.proposal-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-image {
  height: 128px;
}

.proposal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
