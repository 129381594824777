<template>
  <div class="field" :class="isLoading ? 'is-disabled' : ''">
    <label class="label">Check token balance of address</label>
    <div class="control">
      <input
        class="input"
        type="text"
        @change="balanceOf"
        v-model="otBalanceCheckAddr"
        placeholder="Ethereum address"
      />
    </div>
    <div class="control mt-4">
      <input
        class="input"
        type="number"
        disabled="true"
        v-model="ethState.otBalanceOf"
        placeholder="Balance"
      />
    </div>
  </div>
</template>

<script>
import { state, getOTBalaceOf } from "../../utils/ethereum";

export default {
  name: "OTBalanceChecker",
  data: () => ({
    isLoading: false,
    ethState: state,
    otBalanceCheckAddr: null,
  }),
  methods: {
    async balanceOf() {
      this.isLoading = true;
      await getOTBalaceOf(this.otBalanceCheckAddr);
      this.isLoading = false;
    },
  },
};
</script>
