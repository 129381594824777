
const isProduction = process.env.VUE_APP_ENV === 'production'

const stagingId = '74b12d9e-d955-44c5-a1bd-344b4d74842d'
const productionId = 'c42ef2d9-ee39-4adb-b2a7-4c16fdc840f6'

const host = isProduction ? 'minter.open-town.org' : 'staging-minter.open-town.org'
const id   = isProduction ? productionId : stagingId

const open = ()=> {
  window.open(
    `https://${host}/?id=${id}`,
    'pagename',
    'resizable,height=700,width=400'
  )
}

export const useMinter = ()=> {
  return { open }
}
