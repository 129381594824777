<template>
  <div>
    <!-- Status is not yet. Background color is grey -->
    <div
      v-if="data.status.isNone"
      class="potato-farm clickable-area is-clickable"
    >
      <img
        src="~@/assets/desktop/facility/colored/img_potato_farm_colored_step1.png"
        width="410"
        height="152"
        alt="potato farm"
      />
      <img
        :class="{ 'is-active-modal': isColoredFacility }"
        src="~@/assets/desktop/facility/grey/img_potato_farm_grey_step1.png"
        width="410"
        height="152"
        alt="potato farm"
      />
    </div>
    <!-- Status is processing or finished -->
    <div v-else>
      <div v-if="data.funds.hasAllCollected">
        <div class="potato-farm">
          <img
            src="~@/assets/desktop/facility/colored/img_potato_farm_colored_step1.png"
            width="410"
            height="152"
            alt="potato farm"
          />
        </div>
        <img
          v-if="data.status.isProcessing"
          class="building-gate"
          src="~@/assets/desktop/facility/effect/img_building_now_100px.png"
          width="274"
          height="100"
          alt="building gate"
        />
        <img
          v-else-if="data.status.isFinished"
          class="build-finished-effect"
          src="~@/assets/desktop/facility/effect/gif_finished_134px.gif"
          width="400"
          height="72"
          alt="finished effect"
        />
      </div>
      <div
        v-else-if="data.funds.hasPartialCollected"
        class="clickable-area is-clickable"
      >
        <div class="potato-farm">
          <img
            src="~@/assets/desktop/facility/colored/img_potato_farm_colored_step1.png"
            width="410"
            height="152"
            alt="potato farm"
          />
        </div>
        <img
          v-if="data.status.isFinished"
          class="build-finished-effect"
          src="~@/assets/desktop/facility/effect/gif_finished_134px.gif"
          width="240"
          height="40"
          alt="finished effect"
        />
        <img
          class="board"
          src="~@/assets/desktop/facility/effect/img_more_facilities_112px.png"
          width="122"
          height="122"
          alt="board"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    isColoredFacility: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.potato-farm img {
  position: absolute;
  top: 24vh;
  left: 2vw;
  width: 24vw;
}
.potato-farm:hover img:nth-child(2) {
  opacity: 0;
}
.is-active-modal {
  opacity: 0;
}
.board {
  position: absolute;
  top: 30vh;
  left: 16vw;
  width: 9vw;
}
.building-gate {
  position: absolute;
  top: 32vh;
  left: 8vw;
  width: 16vw;
}
.build-finished-effect {
  position: absolute;
  top: 18vh;
  left: 4vw;
  width: 20vw;
}
.clickable-area img {
  transition: 0.3s all;
}
.clickable-area img:hover {
  transform: scale(0.8, 0.8);
  transition: 0.3s all;
}
.clickable-area:hover img:nth-child(1) {
  transform: scale(0.8, 0.8);
  transition: 0.3s all;
}
</style>
