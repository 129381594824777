<template>
  <div class="pb-32" >

    <!-- mobile_header -->
    <div class="header-menu pd-8">
      <div class="is-flex align-items-center is-7">
        <div class="mr-8">
          <img
            src="~@/assets/img_logo.png"
            width="64"
            height="64"
            alt="logo"
          />
        </div>
        <h1 class="font-size-32 font-size-24-mobile">Savanna Kidz NFT</h1>
      </div>
      <div class="hamburger_button" v-on:click="isMenuActive = !isMenuActive">
        <span
          class="line line_01"
          :class="{ button_line01: isMenuActive }"
        ></span>
        <span
          class="line line_02"
          :class="{ button_line02: isMenuActive }"
        ></span>
        <span
          class="line line_03"
          :class="{ button_line03: isMenuActive }"
        ></span>
      </div>
      <transition name="menu">
        <div class="menu" v-show="isMenuActive">
          <ul>
            <li>
              <a :href="docsUrl" target="_blank" class="pd-24-pc">
                <img
                  src="~@/assets/ic_document.png"
                  width="24"
                  height="24"
                  alt="document icon"
                  class="mr-8 mt-32"
                />
                <span class="font-size-24">document</span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/savannakidz"
                target="_blank"
              >
                <img
                  src="~@/assets/ic_twitter.png"
                  width="24"
                  height="24"
                  alt="twitter logo"
                  class="mr-8"
                />
                <span class="font-size-24">twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/tGR4GwnS9S"
                target="_blank"
              >
                <img
                  src="~@/assets/ic_discord.png"
                  width="24"
                  height="24"
                  alt="discord logo"
                  class="mr-8"
                />
                <span class="font-size-24">discord</span>
              </a>
            </li>
            <li>
              <a
                href="https://opensea.io/collection/savanna-kidz"
                target="_blank"
              >
                <img
                  src="~@/assets/ic_opensea.png"
                  width="24"
                  height="24"
                  alt="opensea logo"
                  class="mr-8"
                />
                <span class="font-size-24">opensea</span>
              </a>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <!-- /mobile_header -->

    <!-- content -->
    <Slider :height="'40vh'" :images="images" />

    <div class='pd-16'>
      <h1 class='font-size-24 bold'>
        A project to continue to make autonomous towns in rural Uganda with proceeds from NFT Art.
      </h1>

      <primary-button
        class="mt-16"
        @click="openMinter()"
        :buttonText="'Buy an NFT'"
      />

      <div class='font-size-16 mt-24'>
        Savanna Kidz is Open Town's first project using Web3 technology to raise funds and supporters around the world. We built water tanks and a new school building in Karungu Village, Uganda!
      </div>

      <div class='link-opentown mt-8'>
        <a href="https://opentown.org" target="_blank">
          <span class="link font-size-16">What is Open Town?</span>
        </a>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, defineComponent } from "vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import Slider from "@/components/Slider.vue";
import { useDocuments } from "@/logic/useDocuments";
import { useMinter } from '@/logic/useMinter'

export default defineComponent({
  components: {
    PrimaryButton,
    Slider,
  },
  data() {
    return {
      images: [
        require('@/assets/img_firstview_1_mobile.png'),
        require('@/assets/img_firstview_2_mobile.png'),
        require('@/assets/img_firstview_3_mobile.png'),
      ],
    }
  },
  setup() {
    const { docsUrl } = useDocuments();
    const { open: openMinter } = useMinter()

    const isMenuActive = ref(false);
    const windowWidth = ref(null);

    return {
      docsUrl,
      isMenuActive,
      windowWidth,
      openMinter,
    };
  },
});
</script>

<style lang="scss" scoped>

.link-opentown {
  display: flex;
  justify-content: flex-end;
}

.header-menu {
  position: relative;
  z-index: $menu-lay;
  background-color: $yellow;
  border-bottom: 4px solid #000000;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// menu
.hamburger_button {
  position: absolute;
  top: 8px;
  right: 0;
  width: 72px;
  height: 72px;
  cursor: pointer;
  z-index: $modal-lay;
}

.hamburger_button .line {
  position: absolute;
  right: 0;
  left: 16px;
  width: 32px;
  height: 4px;
  border-radius: 2px;
  background: $default-text-color;
  text-align: center;
}

.hamburger_button .line_01 {
  top: 16px;
  transition: 0.2s ease;
}
.hamburger_button .line_02 {
  top: 26px;
  transition: 0.2s ease;
}
.hamburger_button .line_03 {
  top: 36px;
  transition: 0.2s ease;
}

.button_line01 {
  transform: translateY(10px) rotate(-45deg);
  transition: 0.4s ease;
}
.button_line02 {
  transition: 0.4s ease;
  opacity: 0;
}
.button_line03 {
  transform: translateY(-10px) rotate(45deg);
  transition: 0.4s ease;
}

.menu-enter-active,
.menu-leave-active {
  transition: opacity 0.2s;
}
.menu-enter,
.menu-leave-to {
  opacity: 0;
}
.menu-leave,
.menu-enter-to {
  opacity: 1;
}

.menu li {
  list-style: none;
  line-height: 1;
  padding: 1rem;
}
.menu {
  background-color: $light_yellow;
  z-index: $menu-lay;
  padding: 2rem 1rem;
  position: fixed;
  width: 20rem;
  height: 80rem;
  top: 0;
  right: 0;
}
.menu a {
  color: $default-text-color;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0 2rem;
}
.menu ul {
  margin: 1rem;
  padding: 0;
}
</style>
