import { createApp, h } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import routes from "./routes";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const SimpleRouterApp = {
  data: () => ({
    currentRoute: window.location.pathname,
  }),

  computed: {
    ViewComponent() {
      const matchingPage = routes[this.currentRoute] || "404";
      return require(`./pages/${matchingPage}.vue`).default;
    },
  },

  render() {
    return h(this.ViewComponent);
  },

  created() {
    window.addEventListener("popstate", () => {
      this.currentRoute = window.location.pathname;
    });
  },
};

const app = createApp(SimpleRouterApp);

Sentry.init({
  app,
  dsn: "https://46d498bf01664b19acbed2538d9f143e@o514957.ingest.sentry.io/6181793",
  environment: process.env.VUE_APP_ENV,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        "localhost",
        "savannakidz.com",
        "https://open-town-nft-staging.uc.r.appspot.com/",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.use(
  createGtm({
    id: "GTM-PSKKSJG",
    enabled: process.env.VUE_APP_ENV === "production",
  })
);
app.mount("#app");
