<template>
  <div>
    <Header />
    <Slider :images="images" height="calc(100vh - 100px)" >
      <template #footer>

        <div class="common-section-container">
          <h1 class='font-size-48 bold inverted content-title'>
            A project to continue to make autonomous towns in rural Uganda with proceeds from NFT Art.
          </h1>
          <div class='font-size-24 inverted'>
            Savanna Kidz is Open Town's first project using Web3 technology to raise funds and supporters around the world. We built water tanks and a new school building in Karungu Village, Uganda!
          </div>

          <div class='slide-footer mt-8'>
            <a href='https://open-town.org/' target='_blank'>
              <div class='button-ot pd-8 pl-16 pr-16'>
                <img src="@/assets/ic_opentown.png" width='32' height='32' />
                <span class='font-size-20 inverted ml-8'>
                  What is Open Town?
                </span>
              </div>
            </a>
          </div>
        </div>
      </template>
    </Slider>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Slider from '@/components/Slider.vue';

export default {
  name: "FirstView",
  components: {
    Header,
    Slider,
  },
  data() {
    return {
      images: [
        require('@/assets/img_firstview_1.png'),
        require('@/assets/img_firstview_2.png'),
        require('@/assets/img_firstview_3.png'),
      ],
    }
  },
};

</script>

<style lang="scss" scoped>
.content-title{
  max-width: 80%;
  line-height: 3.5rem;
}

.slide-footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-ot{
  background: $primary;
  border: 4px solid #000000;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>
