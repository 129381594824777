<template>
  <div class="section-height">
    <div class="common-section-container">
      <h3 class="font-size-48-pc font-size-36">The Team</h3>

      <div class="columns is-mobile center">
        <div
          v-for="member of coreMembers"
          :key="member.index"
          class='column is-one-quarter-desktop is-one-third-tablet is-half-mobile '
        >
          <Member :member="member" />
        </div>
      </div>

      <div class="columns is-multiline is-mobile">
        <div
          v-for="member of members"
          :key="member.index"
          class='column is-one-quarter-desktop is-one-third-tablet is-half-mobile '
        >
          <Member :member="member" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Member from '@/components/Team/Member.vue'
export default {
  name: 'TeamSection',
  components: {
    Member,
  },
  data() {
    return {
      coreMembers: [
        {
          role: 'Co-founder',
          name: 'Buhungiro Andrew',
          desc: 'Andrew is the co-founder and president of Faith Angels Ministry. He graduated from an urban university, studied abroad, and returned to his village of Karungu to found Faith Angels Ministry for the children of his home village.',
          img: require('@/assets/img_andrew_160px.png'),
        },
        {
          role: 'Co-founder',
          name: 'Mutungi Duncan',
          desc: 'Duncan is the co-founder and executive director of Faith Angels Ministry. He works every day to help improve the lives of the people of Karungu Village.',
          img: require('@/assets/img_duncan_160px.png'),
        }
      ],

      members: [
        {
          role: 'Chairman of school',
          name: 'Nyehikira Richard',
          desc: 'He is the chairman of the management committee of Faith Angel Nursery and Elementary School, an orphanage in Karungu Village.',
          img: require('@/assets/img_richard_160px.png'),
        },
        {
          role: 'Elementary school student',
          name: 'Joan Ainembabazi',
          desc: 'Yoanne is a 4th-grade elementary school student and her best subject is English. She is one of the brightest students in her class. She enjoys telling stories and making new friends. Her dream is to become a nurse.',
          img: require('@/assets/img_joan_160px.png'),
        },
        {
          role: 'Elementary school student',
          name: 'Gumoshabe Adella',
          desc: 'Adela is a 7th grade elementary school student and is taking her graduation exams this November. She has served as president of her elementary school for three years. She hopes to become a politician in the future. She likes to eat rice.',
          img: require('@/assets/img_adella_160px.png'),
        },
        {
          role: 'Leaders of the village',
          name: 'Siliver Tumwesigye',
          desc: 'Mr. Siliver is one of the leaders of the Karungu village. He has a wife and six children. All of his children are married. He comes from a poor family and works hard.  He hopes his grandchildren will complete their education through college and become contributing members of the community.',
          img: require('@/assets/img_siliver_160px.png'),
        },
        {
          role: "Artist/Designer",
          name: "Annie Mizuki",
          desc: "Annie is an artist, designer, and front-end engineer based in Japan. She expresses the world of Savanna Kidz with her innovative ideas.",
          img: require('@/assets/img_annie_mizuki_160px.png'),
        },
        {
          role: "Lead Developer",
          name: "Nikolai",
          desc: "Nikolai is a full-stack software engineer and blockchain enthusiast based in Tokyo, Japan. Past experience includes building global social networks and crowdfunding platforms.",
          img: require('@/assets/img_nikolai_160px.png'),
        },
        {
          role: "International Dev. Consultant",
          name: "Akiko",
          desc: "Akiko is an international development consultant. She is enthusiastic in creating a better for future generations.  She is in charge of advising and coordinating with the NGOs.",
          img: require('@/assets/img_akiko_160px.png'),
        },
        {
          role: "International Dev. Consultant",
          name: "Lisa",
          desc: "Lisa is an international development consultant. She has worked for many African countries in her career. A business development lead who promotes Open Town Project worldwide.",
          img: require('@/assets/img_lisa_160px.png'),
        },
        {
          role: "Assistant Project Manager",
          name: "Daniel",
          desc: "Daniel is a web developer and currently living in Japan. His past experiences include web development, product assessment and client relations.",
          img: require('@/assets/img_daniel_160px.png'),
        },
        {
          role: "Consultant Specialist",
          name: "Lourdes",
          desc: "Lourdes, a Spanish based in Tokyo, is a consultant specialist on emerging countries development, who loves music and learning about different cultures.",
          img: require('@/assets/img_lourdes_160px.png'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

.center{
  justify-content: center;
}

</style>
