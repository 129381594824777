<template>
  <div class="width">
    <crossmint-pay-button
      collectionTitle="Savanna Kidz (SK)"
      collectionDescription="Savanna Kidz is a collection of 10,000 art pieces that represent the DAO community-driven Open Town Project.
Open Town Project is an experimental project to create a self-sustaining decentralized cities in the real world with the power of the crypto world.
Created by Annie, Savanna Kidz NFTs vividly depict children living in Uganda, the first citizens of the Open Town Project."
      collectionPhoto="https://www.savannakidz.com/img/img_logo.c0db39e3.png"
      clientId="5a3f2670-dbca-46ce-bbbb-6ea5eaf949cb"
      :mintConfig="{
        type: 'erc-721',
        totalPrice: String(mintPrice),
        mintAmount: String(mintCount),
      }"
      class="mint-button-style"
      :environment="environment"
      @click="sendEvent"
    />
  </div>
</template>

<script>
require("@crossmint/client-sdk-vanilla-ui");

export default {
  name: "Crossmint",
  props: {
    mintPrice: {
      type: Number,
      required: true,
    },
    mintCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    environment() {
      return process.env.VUE_APP_ENV === "production" ? "" : "staging";
    },
  },
  methods: {
    sendEvent() {
      this.$gtm.trackEvent({
        category: "purchase",
        action: "buy_with_card",
        label: "crossmint",
        value: this.mintPrice,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mint-button-style::part(button) {
  background: $bright-blue;
  border-radius: 32px;
  border: 5px solid black;
  font-family: Lexend Deca, Helvetica, Arial, sans-serif;
  font-size: $font-size-16;
  font-weight: normal;
  box-shadow: none;
  height: 60px;
}
@media (min-width: $tablet-min-width) {
  .mint-button-style::part(button) {
    font-size: $font-size-24;
  }
}
</style>
