<template>
  <div>
    <div class="section-height white-bg-color">
      <div class="common-section-container">
        <h3 class="font-size-48 font-size-36-mobile">
          How Savanna Kidz works
        </h3>
        <p class="font-size-24 font-size-16-mobile mt-32 mt-16-mobile mb-32 mb-16-mobile">
          The project team in Uganda and Japan will take the lead in selling NFT art and using the proceeds to promote community development. The NFT holders will vote on how to promote community development based on the options presented by the villagers.
        </p>

        <picture>
          <source media="(max-width:769px)" srcset="~@/assets/img_how_it_works_mobile.png">
          <img
            src="~@/assets/img_how_it_works_desktop.png"
            alt="how it works"
          />
        </picture>
      </div>

    </div>
  </div>
</template>

