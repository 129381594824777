<template>
  <div class="field" :class="isLoading ? 'is-disabled' : ''">
    <label class="label">Airdrop</label>
    <div class="columns">
      <div class="column">
        <div class="control">
          <textarea
            class="textarea"
            @change="parseInput"
            v-model="addressString"
            placeholder="0x0000000000000000000000000000000000000000,
0x0000000000000000000000000000000000000000,
0x0000000000000000000000000000000000000000"
          ></textarea>
          <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>
        </div>
      </div>
      <div class="column is-one-fifth">
        <input
          class="input"
          type="number"
          min="1"
          max="200"
          @change="validateQuantity"
          v-model="tokensPerAddress"
          placeholder="Quantity"
        />
        <div class="tags has-addons mt-4">
          <span class="tag">Available</span>
          <span class="tag is-primary">{{
            ethState.skAvailableToReserve
          }}</span>
        </div>
        <div class="tags has-addons">
          <span class="tag">Total selected</span>
          <span class="tag is-primary">{{ totalCount }}</span>
        </div>

        <button
          @click="airdrop"
          class="button is-primary is-fullwidth"
          :disabled="errorMessage || totalCount <= 0"
        >
          Reserve
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  state,
  isValidAddress,
  getSKAvailableToReserve,
  reserve,
} from "../../utils/ethereum";

export default {
  name: "Airdrop",
  data: () => ({
    isLoading: true,
    errorMessage: null,
    ethState: state,
    addressString: "",
    addressArray: [],
    tokensPerAddress: 1,
  }),
  computed: {
    totalCount() {
      return this.addressArray.length * this.tokensPerAddress;
    },
  },
  methods: {
    parseInput() {
      const input = this.addressString
        .replace(/\s+/g, "") // Remove whitespace
        .split(",")
        .filter((n) => n); // Remove empty elements

      this.addressArray = [...new Set(input)]; // Remove duplicates
      this.validateInput();
    },
    validateInput() {
      this.errorMessage = null;
      this.addressArray.forEach((a) => {
        if (!isValidAddress(a)) {
          this.errorMessage = "Invalid address detected";
        }
      });
    },
    validateQuantity() {
      this.errorMessage = null;
      if (this.totalCount > 20) {
        this.errorMessage = "20 tokens per transaction maximum.";
      }
    },
    async airdrop() {
      this.isLoading = true;
      await reserve(this.addressArray, this.tokensPerAddress);
      this.isLoading = false;
    },
  },
  async mounted() {
    await getSKAvailableToReserve();
    this.isLoading = false;
  },
};
</script>
