<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background" @click="onCloseModal" />
    <div
      class="modal-content white-bg-color has-bold-border white-bg-color has-16-radius pd-16"
    >
      <div class="is-flex justify-content-space-between">
        <slot name="title" />
        <div class="is-clickable close-button" @click="onCloseModal">
          <img
            src="@/assets/ic_close.png"
            aria-label="close"
            alt="close icon"
            height="30"
            width="32"
          />
        </div>
      </div>
      <slot name="contents" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const onCloseModal = () => {
      context.emit("onCloseModal");
    };

    return {
      onCloseModal,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  z-index: $modal-lay;

  .modal-background {
    background-color: rgba(10, 10, 10, 0.2);
  }

  .modal-content {
    animation: fadeIn 0.3s ease 0s 1 normal;
    -webkit-animation: fadeIn 0.3s ease 0s 1 normal;
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 504px;
    box-shadow: 0 10px 24px 0 rgba(255, 225, 76, 0.877);
    transition: 0.3s all;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.close-button img:hover {
  transform: scale(0.9, 0.9);
  transition: 0.2s all;
}
</style>
