<template>
  <div>
    <!-- Status is not yet. Background color is grey -->
    <div
      v-if="data.status.isNone"
      class="water-tank clickable-area is-clickable"
    >
      <img
        src="~@/assets/desktop/facility/colored/img_water_tank_colored_step1.png"
        width="238"
        height="169"
        alt="water tank"
      />
      <img
        :class="{ 'is-active-modal': isColoredFacility }"
        src="~@/assets/desktop/facility/grey/img_water_tank_grey_step1.png"
        width="238"
        height="169"
        alt="water tank"
      />
    </div>
    <!-- Status is processing or finished -->
    <div v-else>
      <div v-if="data.funds.hasAllCollected">
        <div class="water-tank">
          <img
            src="~@/assets/desktop/facility/colored/img_water_tank_colored_step1.png"
            width="238"
            height="169"
            alt="water tank"
          />
        </div>
        <img
          v-if="data.status.isProcessing"
          class="building-gate"
          src="~@/assets/desktop/facility/effect/img_building_now_100px.png"
          width="274"
          height="100"
          alt="building gate"
        />
        <img
          v-else-if="data.status.isFinished"
          class="build-finished-effect"
          src="~@/assets/desktop/facility/effect/gif_finished_134px.gif"
          width="400"
          height="72"
          alt="finished effect"
        />
      </div>
      <div
        v-else-if="data.funds.hasPartialCollected"
        class="clickable-area is-clickable"
      >
        <div class="water-tank">
          <img
            src="~@/assets/desktop/facility/colored/img_water_tank_colored_step1.png"
            width="238"
            height="169"
            alt="water tank"
          />
        </div>
        <img
          v-if="data.status.isFinished"
          class="build-finished-effect"
          src="~@/assets/desktop/facility/effect/gif_finished_134px.gif"
          width="240"
          height="40"
          alt="finished effect"
        />
        <img
          class="board"
          src="~@/assets/desktop/facility/effect/img_more_facilities_112px.png"
          width="122"
          height="122"
          alt="board"
        />
      </div>
    </div>
    <a
      href="https://www.youtube.com/watch?v=1WR9vGgSxs0&t=9s"
      target="_blank"
      class="progress-video"
      @click.stop
      @mouseover="hoverAction"
      @mouseleave="leaveAction"
    >
      <div
        v-if="isShowMessage"
        class="message has-thin-border has-16-radius pd-16 white-bg-color"
      >
        <img
          class="is-block"
          src="@/assets/ic_movie.png"
          alt="movie icon"
          onClick=""
          height="40"
          width="40"
        />
        <p>Watch a video about the construction!</p>
      </div>
      <img
        class="is-block"
        src="~@/assets/gif/gif_video_350px.gif"
        width="350"
        height="350"
        alt="progress video"
      />
    </a>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    isColoredFacility: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const isShowMessage = ref(false);

    const hoverAction = () => {
      isShowMessage.value = true;
    };
    const leaveAction = () => {
      isShowMessage.value = false;
    };
    return {
      isShowMessage,
      hoverAction,
      leaveAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.water-tank img {
  position: absolute;
  bottom: 4vh;
  left: 8vw;
  width: 14vw;
}
.water-tank:hover img:nth-child(2) {
  opacity: 0;
}
.is-active-modal {
  opacity: 0;
}
.board {
  position: absolute;
  bottom: 3vh;
  left: 2vw;
  width: 9vw;
}
.building-gate {
  position: absolute;
  bottom: 3vh;
  left: 9vw;
  width: 16vw;
}
.build-finished-effect {
  position: absolute;
  bottom: 18vh;
  left: 5vw;
  width: 20vw;
}
.progress-video {
  position: absolute;
  bottom: 4vh;
  left: 22vw;
  width: 8vw;
}
.clickable-area img {
  transition: 0.3s all;
}
.clickable-area img:hover {
  transform: scale(0.8, 0.8);
  transition: 0.3s all;
}
.clickable-area:hover img:nth-child(1) {
  transform: scale(0.8, 0.8);
  transition: 0.3s all;
}
.progress-video {
  transition: 0.3s all;
}
.progress-video :hover {
  transform: scale(0.8, 0.8);
  transition: 0.3s all;
}
.message {
  width: 10vw;
}
</style>
