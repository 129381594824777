import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";
import SavannaKidz from "/abis/SavannaKidz.json";
import OpenTown from "/abis/OpenTown.json";
import { reactive } from "vue";
import * as Sentry from "@sentry/browser";

const DEBUG = process.env.VUE_APP_ENV !== "production";
const GANACHE_PROVIDER = "ws://127.0.0.1:7545";

export const state = reactive({
  errorMessage: null,
  isAdmin: false,
  ethereum: null,
  web3: null,
  chainId: null,
  network: null,
  accounts: [],
  address: null,
  addressBalance: 0,

  // SavannaKidz
  skContract: null,
  skEthBalance: 0,
  skOwner: null,
  skSupply: 0,
  skAvailableToReserve: 0,
  skBalanceOf: 0,
  skOwnerOf: null,
  skTotalClaimable: 0,
  skCurrentOTAddress: null,
  skCurrentFAMAddress: null,
  skIsSaleActive: false,
  skIsPresaleActive: false,
  skTokenURI: null,

  // $OPENTOWN
  otContract: null,
  otOwner: null,
  otSupply: 0,
  otBalanceOf: 0,
  otBalanceOfAdmin: 0,
  otIsSKAuthorized: false,

  // Payment
  lastPaymentReceipt: null,
});

export var accountChangeListeners = [];

/**
 * Connection
 **/

export async function checkProvider() {
  try {
    const provider = await detectEthereumProvider();

    if (provider && provider === window.ethereum) {
      state.ethereum = provider;
      log("Ethereum successfully detected!");
      await initEthereum();
    } else if (provider) {
      log(
        "Unable to connect to wallet. Do you have multiple wallets installed?"
      );
    } else {
      log("Please install MetaMask!");
    }
  } catch (e) {
    error(e);
  }
}

export function removeEventListeners() {
  if (!state.ethereum) return;
  state.ethereum.removeListener("accountsChanged", handleAccountsChanged);
  state.ethereum.removeListener("chainChanged", handleChainChanged);
  accountChangeListeners = [];
}

export async function connectWallet() {
  try {
    const accounts = await state.ethereum.request({
      method: "eth_requestAccounts",
    });
    setAccounts(accounts);
  } catch (e) {
    state.errorMessage = e.message;
    state.accounts = [];
    state.address = null;
    error(e);
  }
}

export async function checkAccess() {
  if (!state.skContract || !state.otContract || !state.address) {
    state.skOwner = null;
    state.otOwner = null;
    state.isAdmin = false;
    return;
  }

  state.skOwner = await state.skContract.methods.owner().call();
  state.otOwner = await state.otContract.methods.owner().call();
  state.isAdmin =
    state.skOwner.toLowerCase() === state.otOwner.toLowerCase() &&
    state.otOwner.toLowerCase() === state.address.toLowerCase();
}

export async function getAddressEthBalance(address) {
  if (!address || !state.web3) {
    state.addressBalance = 0;
    return;
  }

  try {
    const balanceWei = await state.web3.eth.getBalance(address);
    state.addressBalance = await state.web3.utils.fromWei(balanceWei);
  } catch (e) {
    error(e);
    state.addressBalance = 0;
  }
}

/**
 * Contract functions
 **/

// SavannaKidz
export async function getSKEthBalance() {
  if (!state.skContract || !state.web3) {
    state.skEthBalance = 0;
    return;
  }

  try {
    const skEthBalanceWei = await state.web3.eth.getBalance(
      state.skContract.options.address
    );
    state.skEthBalance = round(state.web3.utils.fromWei(skEthBalanceWei));
  } catch (e) {
    error(e);
    state.skEthBalance = 0;
  }
}

export async function getSKAvailableToReserve() {
  if (!state.skContract) {
    state.skAvailableToReserve = 0;
    return;
  }

  try {
    state.skAvailableToReserve = await state.skContract.methods
      .reserved()
      .call();
  } catch (e) {
    error(e);
    state.skAvailableToReserve = 0;
  }
}

export async function mintSK(amount, price) {
  if (
    !state.skContract ||
    !state.address ||
    amount <= 0 ||
    amount > 20 ||
    price <= 0
  )
    return;

  try {
    const receipt = await state.skContract.methods
      .mint(amount)
      .send({ from: state.address, value: price });
    log(receipt);
  } catch (e) {
    error(e);
    return;
  }

  await getSKSupply();
}

export async function presaleMintSK(allowanceInfo, amount, price) {
  if (
    !allowanceInfo ||
    !state.skContract ||
    amount <= 0 ||
    amount > allowanceInfo.maxMintAmount ||
    price <= 0
  )
    return;

  try {
    const receipt = await state.skContract.methods
      .presaleMint(amount, allowanceInfo.maxMintAmount, allowanceInfo.signature)
      .send({ from: state.address, value: price });
    log(receipt);
  } catch (e) {
    error(e);
    return;
  }

  await getSKSupply();
}

export async function reserve(addresses, amount) {
  if (
    !state.skContract ||
    !Array.isArray(addresses) ||
    addresses.length <= 0 ||
    amount <= 0 ||
    amount > 20
  )
    return;

  log(addresses);
  log(amount);

  try {
    const receipt = await state.skContract.methods
      .reserve(addresses, amount)
      .send({ from: state.address });
    log(receipt);
  } catch (e) {
    error(e);
    return;
  }

  await getSKSupply();
  await getSKAvailableToReserve();
}

export async function getSKSupply() {
  if (!state.skContract) {
    state.skSupply = 0;
    return;
  }

  try {
    state.skSupply = await state.skContract.methods.totalSupply().call();
  } catch (e) {
    error(e);
    state.skSupply = 0;
  }
}

export async function getCurrentOTAddress() {
  if (!state.skContract) {
    state.skCurrentOTAddress = null;
    return;
  }

  try {
    state.skCurrentOTAddress = await state.skContract.methods
      .openTownContract()
      .call();
  } catch (e) {
    error(e);
    state.skCurrentOTAddress = null;
  }
}

export async function updateOTAddress() {
  if (!state.skContract || !state.otContract || !state.address) return;

  try {
    const receipt = await state.skContract.methods
      .setOpenTown(state.otContract.options.address)
      .send({ from: state.address });
    log(receipt);
  } catch (e) {
    error(e);
    return;
  }
  await getCurrentOTAddress();
}

export async function getCurrentFAMAddress() {
  if (!state.skContract) {
    state.skCurrentFAMAddress = null;
    return;
  }

  try {
    state.skCurrentFAMAddress = await state.skContract.methods
      .famWallet()
      .call();
  } catch (e) {
    error(e);
    state.skCurrentFAMAddress = null;
  }
}

export async function updateFAMAddress(newAddress) {
  if (!state.skContract || !state.address || !newAddress) return;

  try {
    const receipt = await state.skContract.methods
      .setFamWallet(newAddress)
      .send({ from: state.address });
    log(receipt);
  } catch (e) {
    error(e);
    return;
  }

  await getCurrentFAMAddress();
}

export async function getSaleStatus() {
  if (!state.skContract) {
    state.skIsSaleActive = false;
    return;
  }

  try {
    state.skIsSaleActive = await state.skContract.methods.isSaleActive().call();
  } catch (e) {
    error(e);
    state.skIsSaleActive = false;
  }
}

export async function toggleSale() {
  if (!state.skContract || !state.address) return;

  try {
    const receipt = await state.skContract.methods
      .toggleSale()
      .send({ from: state.address });
    log(receipt);
  } catch (e) {
    error(e);
    return;
  }
  await getSaleStatus();
}

export async function getPresaleStatus() {
  if (!state.skContract) {
    state.skIsPresaleActive = false;
    return;
  }

  try {
    state.skIsPresaleActive = await state.skContract.methods
      .isPresaleActive()
      .call();
  } catch (e) {
    error(e);
    state.skIsPresaleActive = false;
  }
}

export async function togglePresale() {
  if (!state.skContract || !state.address) return;

  try {
    const receipt = await state.skContract.methods
      .togglePresale()
      .send({ from: state.address });
    log(receipt);
  } catch (e) {
    error(e);
    return;
  }
  await getPresaleStatus();
}

export async function getSKBalaceOf(address) {
  if (!state.skContract || !address) {
    state.skBalanceOf = 0;
    return;
  }

  try {
    state.skBalanceOf = await state.skContract.methods
      .balanceOf(address)
      .call();
  } catch (e) {
    error(e);
    state.skBalanceOf = 0;
  }
}

export async function getSKOwnerOf(tokenId) {
  if (!state.skContract || !tokenId) {
    state.skOwnerOf = null;
    return;
  }

  try {
    state.skOwnerOf = await state.skContract.methods.ownerOf(tokenId).call();
  } catch (e) {
    error(e);
    state.skOwnerOf = null;
  }
}

export async function getTokenURIOf(tokenId) {
  if (!state.skContract || !tokenId) {
    state.skTokenURI = null;
    return;
  }

  try {
    state.skTokenURI = await state.skContract.methods.tokenURI(tokenId).call();
  } catch (e) {
    error(e);
    state.skTokenURI = null;
  }
}

export async function withdrawFromSK() {
  if (!state.skContract || !state.address) return;

  try {
    const receipt = await state.skContract.methods
      .withdraw()
      .send({ from: state.address });
    log(receipt);
  } catch (e) {
    error(e);
    return;
  }

  await getSKEthBalance();
}

// OpenTown
export async function getOTSupply() {
  if (!state.otContract || !state.web3) {
    state.otSupply = 0;
    return;
  }

  try {
    const otSupplyWei = await state.otContract.methods.totalSupply().call();

    state.otSupply = round(state.web3.utils.fromWei(otSupplyWei));
    log(state.otSupply);
  } catch (e) {
    error(e);
    state.otSupply = 0;
  }
}

export async function getOTBalanceOfAdmin() {
  if (!state.otContract || !state.address || !state.web3) {
    state.otBalanceOfAdmin = 0;
    return;
  }

  try {
    const otBalanceWei = await state.otContract.methods
      .balanceOf(state.address)
      .call();
    state.otBalanceOfAdmin = round(state.web3.utils.fromWei(otBalanceWei));
  } catch (e) {
    error(e);
    state.otBalanceOfAdmin = 0;
  }
}

export async function getOTBalaceOf(address) {
  if (!state.otContract || !address || !state.web3) {
    state.otBalanceOf = 0;
    return;
  }

  try {
    const otBalanceWei = await state.otContract.methods
      .balanceOf(address)
      .call();
    state.otBalanceOf = round(state.web3.utils.fromWei(otBalanceWei));
  } catch (e) {
    error(e);
    state.otBalanceOf = 0;
  }
}

export async function getSKAuthStatus() {
  if (!state.otContract || !state.skContract) {
    state.otIsSKAuthorized = false;
    return;
  }

  try {
    state.otIsSKAuthorized = await state.otContract.methods
      .otpContracts(state.skContract.options.address)
      .call();
  } catch (e) {
    error(e);
    state.otIsSKAuthorized = false;
  }
}

export async function authorizeSK() {
  if (!state.otContract || !state.skContract || !state.address) return;

  try {
    const receipt = await state.otContract.methods
      .setOTPContract(state.skContract.options.address, true)
      .send({ from: state.address });
    log(receipt);
  } catch (e) {
    error(e);
  }

  await getSKAuthStatus();
}

export async function burnOT(amount) {
  if (!state.otContract || !state.web3 || !state.address) return;

  try {
    const amountWei = state.web3.utils.toWei(amount.toString());
    const receipt = await state.otContract.methods
      .burn(amountWei)
      .send({ from: state.address });
    log(receipt);
  } catch (e) {
    error(e);
    return;
  }

  await getOTSupply();
  await getOTBalanceOfAdmin();
}

export async function getTotalClaimable() {
  if (!state.skContract || !state.address || !state.web3) {
    state.skTotalClaimable = 0;
    return;
  }

  try {
    const totalClaimableWei = await state.skContract.methods
      .getTotalClaimable(state.address)
      .call();

    state.skTotalClaimable = round(state.web3.utils.fromWei(totalClaimableWei));
  } catch (e) {
    error(e);
    state.skTotalClaimable = 0;
  }
}

export async function getReward() {
  if (!state.skContract || !state.address) return;

  try {
    const receipt = await state.skContract.methods
      .getReward()
      .send({ from: state.address });
    log(receipt);
  } catch (e) {
    error(e);
    return;
  }

  await getOTBalanceOfAdmin();
  await getTotalClaimable();
}

export function isValidAddress(address) {
  return state.web3.utils.isAddress(address);
}

/**
 * Payment
 **/

export async function sendPayment(address, amountEth) {
  state.lastPaymentReceipt = null;
  if (!state.web3 || !state.address) return;

  try {
    state.lastPaymentReceipt = await state.web3.eth.sendTransaction({
      from: state.address,
      to: address,
      value: state.web3.utils.toWei(amountEth),
    });
    log(state.lastPaymentReceipt);
  } catch (e) {
    error(e);
  }
}

/**
 * Utility
 **/
async function initEthereum() {
  initEventListeners();
  await initWeb3();

  if (!isValidChain()) {
    error("Unsupported chain. Please connect to Mainnet.");
    return;
  }

  await getAccounts();
  initSKContract();
  initOTContract();
}

function initEventListeners() {
  if (!state.ethereum) return;
  state.ethereum.on("accountsChanged", handleAccountsChanged);
  state.ethereum.on("chainChanged", handleChainChanged);
}

const handleChainChanged = (chainId) => {
  window.location.reload(); // Recommended
  log("Chain changed", chainId);
};

const handleAccountsChanged = (accounts) => {
  setAccounts(accounts);
  checkAccess();
  accountChangeListeners.forEach((f) => f());
};

function isValidChain() {
  // 0x1	1	Ethereum Main Network (Mainnet)
  // 0x3	3	Ropsten Test Network
  // 0x4	4	Rinkeby Test Network
  // 0x5	5	Goerli Test Network
  // 0x2a	42	Kovan Test Network
  // 0x539 1337 Ganache
  if (DEBUG) {
    return [1, 4, 1337].includes(state.chainId);
  } else {
    return [1].includes(state.chainId);
  }
}

async function initWeb3() {
  state.web3 = new Web3(Web3.givenProvider || GANACHE_PROVIDER);

  try {
    state.chainId = await state.web3.eth.getChainId();
    state.network = await state.web3.eth.net.getId();
    log(`chain ID: ${state.chainId}`);
    log(`network: ${state.network}`);
  } catch (e) {
    state.chainId = null;
    state.network = null;
    error(e);
  }
}

async function getAccounts() {
  try {
    const accounts = await state.web3.eth.getAccounts();
    setAccounts(accounts);
  } catch (e) {
    state.accounts = [];
    state.address = null;
    error(e);
  }
}

function setAccounts(accounts) {
  state.accounts = accounts;
  state.address = accounts[0];
  log("Accounts changed", accounts);
}

function initSKContract() {
  const networkData = SavannaKidz.networks[state.network];

  if (networkData) {
    const abi = SavannaKidz.abi;
    const address = networkData.address;
    state.skContract = new state.web3.eth.Contract(abi, address);
  } else {
    state.skContract = null;
    error("Please wait until the mint day!");
  }
}

function initOTContract() {
  const networkData = OpenTown.networks[state.network];

  if (networkData) {
    const abi = OpenTown.abi;
    const address = networkData.address;
    state.otContract = new state.web3.eth.Contract(abi, address);
  } else {
    state.otContract = null;
    error("Please wait until the mint day!");
  }
}

function log(message) {
  if (DEBUG) {
    Sentry.captureMessage(message);
    console.log(message);
  }
}

function error(e) {
  state.errorMessage = e.message || e;
  console.error(e);
  reportSentryError(e);
}

export function reportSentryError(e) {
  if (e && e.message) {
    const err = new Error(e.message);
    err.name = "Ethereum error";
    err.stack = e.stack;
    Sentry.captureException(err);
  } else {
    Sentry.captureException(e);
  }
}

function round(value) {
  const decimalPlaces = 4;
  return Number(
    Math.round(parseFloat(value + "e" + decimalPlaces)) + "e-" + decimalPlaces
  );
}
