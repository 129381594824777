<template>
  <div class="section-height grey50-bg-color" id='collection'>
    <div class="common-section-container">
      <h3 class="font-size-48-pc font-size-36">Savanna Kidz</h3>
      <p class="font-size-24-pc font-size-16 mt-32">
        This NFT art collection playfully depicts the children who are direct
        beneficiaries of the Open Town project.
      </p>
      <p class="font-size-24-pc font-size-16 mt-16">
        It is composed of 100 distinct animal-inspired characteristics such as
        the eyes, nose, mouth, head, body and background.
      </p>
    </div>
    <div class="is-flex justify-content-center mt-16 light-green-bg-color">
      <img src="~@/assets/img_collection.png" height="312" alt="collection" />
    </div>
  </div>
</template>
