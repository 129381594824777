<template>
  <div :class="isLoading ? 'is-disabled' : ''">
    <div class="tags has-addons">
      <span class="tag is-medium">Total supply</span>
      <span class="tag is-medium is-primary is-light">{{
        ethState.otSupply
      }}</span>
    </div>
    <div class="tags has-addons">
      <span class="tag">Address</span>
      <span class="tag is-info is-light">{{
        ethState.otContract.options.address
      }}</span>
    </div>
    <div class="tags has-addons">
      <span class="tag">SavannaKidz authorized</span>
      <span
        class="tag is-light"
        :class="`is-${ethState.otIsSKAuthorized ? 'success' : 'danger'}`"
        >{{ ethState.otIsSKAuthorized ? "YES" : "NO" }}</span
      >
      <a @click="authzSK" class="ml-4 is-size-7">Authorize</a>
    </div>
  </div>
</template>

<script>
import {
  state,
  getOTSupply,
  getSKAuthStatus,
  authorizeSK,
} from "../../utils/ethereum";

export default {
  name: "OTMain",
  data: () => ({
    isLoading: true,
    ethState: state,
  }),
  methods: {
    async fetchContractData() {
      this.isLoading = true;
      await getOTSupply();
      await getSKAuthStatus();
      this.isLoading = false;
    },
    async authzSK() {
      this.isLoading = true;
      await authorizeSK();
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.fetchContractData();
  },
};
</script>
