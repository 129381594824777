<template>
  <div class="effect">
    <div class="button-style" :class="isDisabled ? 'disabled' : 'clickable'">
      <a
        class="is-flex justify-content-center align-items-center"
        :href="linkUrl"
        target="_blank"
      >
        <img
          v-if="buttonIcon"
          :src="buttonIcon"
          class="ml-8"
          height="32"
          width="32"
          alt="button icon"
        />
        <p
          class="white-text-color font-size-24-pc font-size-20 ml-16 mr-16 adjust-text"
        >
          {{ buttonText }}
        </p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    linkUrl: {
      type: String,
      required: false,
    },
    buttonIcon: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>

@media (max-width: $tablet-min-width) {
  .adjust-text {
    margin-top: 4px;
  }
}
.button-style {
  min-height: 60px;
  padding: 8px;
  border: 5px solid black;
  border-radius: 32px;

  &.clickable {
    background-color: $middle-blue-green;
    cursor: pointer;
  }
  &.disabled {
    background-color: $middle-blue-green;
    opacity: 0.5;
    pointer-events: none;
  }
}
.effect {
  transition: 0.3s all;
}
.effect div:hover {
  transform: scale(0.95, 0.95);
  transition: 0.3s all;
  background-color: $bright-blue;
}
</style>
