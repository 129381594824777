<template>
  <div class="modal" :class="isShown ? 'is-active' : ''">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p v-if="isLoading" class="modal-card-title">Loading NFT</p>
        <p v-if="metadata" class="modal-card-title">{{ metadata.name }}</p>
        <button @click="close" class="delete" aria-label="close"></button>
      </header>
      <section v-if="metadata" class="modal-card-body">
        <div class="columns">
          <div class="column is-7">
            <figure class="image mb-4">
              <img :src="parsedURI(metadata.image)" />
            </figure>
          </div>
          <div class="column">
            <p
              v-for="attr in metadata.attributes"
              v-bind:key="metadata.attributes.indexOf(attr)"
            >
              <strong>{{ attr.trait_type }}</strong>
              <span class="tag ml-2">{{ attr.value }}</span>
            </p>
          </div>
        </div>
        <div class="block">
          {{ metadata.description }}
        </div>
      </section>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import { state, getTokenURIOf } from "../../utils/ethereum";

export default {
  name: "NFTViewer",
  props: {
    shouldShow: Boolean,
    tokenID: Number,
  },
  data: () => ({
    isShown: false,
    isLoading: true,
    ethState: state,
    metadata: null,
  }),
  methods: {
    parsedURI(uri) {
      const id = uri.replace("ipfs://", "");
      return `https://ipfs.io/ipfs/${id}`;
    },
    async loadMetadata() {
      const res = await axios.get(this.parsedURI(this.ethState.skTokenURI));
      this.metadata = res.data;
    },
    async show() {
      this.isLoading = true;
      this.isShown = true;
      this.metadata = null;
      await getTokenURIOf(this.tokenID);
      await this.loadMetadata();
      this.isLoading = false;
    },
    close() {
      this.isShown = false;
    },
  },
};
</script>
