<template>
  <div
    class="light-yellow-bg-color full-height is-flex align-items-center justify-content-center"
  >
    <div class="pd-16-pc">
      <div class="common-section-container">
        <h1 class="title is-flex justify-content-center pd-16">
          1. Claim your $OPENTOWN!
        </h1>
        <div v-if="isLoading">Loading...</div>
        <div v-else-if="ethState.errorMessage" class="notification is-danger">
          {{ ethState.errorMessage }}
        </div>
        <div v-else-if="!ethState.address" class="has-text-centered">
          <button
            @click="connect"
            class="button is-primary button is-rounded is-medium has-bold-border mt-4"
          >
            Connect wallet here
          </button>
          <div class="mt-32">
            <img
              src="~@/assets/ic_arrow_down.svg"
              height="48"
              width="48"
              alt="vision"
            />
          </div>
          <h1 class="title is-flex justify-content-center mt-48">
            2. Vote for the project on Discord!
          </h1>
          <p class="mt-16">
            Once you claimed your $OPENTOWN tokens you can vote for the project
            on Discord!
          </p>
          <div
            class="mt-16 content is-flex justify-content-center has-text-justified"
          >
            <ol>
              <li>
                Join SavannaKidz discord channel from
                <a :href="linkToVote" target="_blank">this link.</a>
              </li>
              <li>Go to the <strong>verify-tokens</strong> channel.</li>
              <li>
                Click on <strong>Let's go!</strong> button in the
                <strong>Verify your assets</strong> message.
              </li>
              <li>Click on <strong>Connect wallet</strong>.</li>
              <li>
                Check that you have selected correct address in MetaMask and
                sign the message.
              </li>
              <li>
                Verify that you've received the <strong>$OPENTOWN</strong> role.
              </li>
              <li>Go to the <strong>council-voting</strong> channel.</li>
              <li>Cast a vote on your desired proposal option.</li>
            </ol>
          </div>
          <p>*Votes take some time to be counted, so please be patient.</p>
          <div
            class="is-flex-desktop align-items-center justify-content-center"
          >
            <div>
              <img
                src="~@/assets/img_claim_ot_1.png"
                height="400"
                width="700"
                alt="claim_ot"
                class="is-hidden-mobile"
              />
            </div>
            <a
              :href="linkToVote"
              class="has-bold-border is-block deep-blue-bg-color mt-16 pd-16 has-16-radius"
              target="_blank"
            >
              <img
                src="~@/assets/img_vote.png"
                height="150"
                width="180"
                alt="vote"
              />
              <p class="white-text-color font-size-24">Let's go vote here!</p>
            </a>
          </div>
        </div>
        <div v-else class="has-text-centered">
          <p>
            You currently own
            <span class="tag is-dark is-medium"
              >{{ ethState.otBalanceOfAdmin }} $OPENTOWN</span
            >
            on
            <span class="tag">{{ ethState.address }}</span>
          </p>
          <p class="mt-4">
            You can claim
            <span class="tag is-danger is-large"
              >{{ ethState.skTotalClaimable }} $OPENTOWN</span
            >
          </p>
          <button
            @click="claim"
            class="button is-primary button is-rounded is-medium has-bold-border mt-16"
            :disabled="!canClaim"
          >
            Claim tokens here!
          </button>
          <div class="mt-32">
            <img
              src="~@/assets/ic_arrow_down.svg"
              height="48"
              width="48"
              alt="vision"
            />
          </div>
          <h1 class="title is-flex justify-content-center mt-48">
            2. Vote for the project on Discord!
          </h1>
          <p class="mt-16">
            Once you claimed your $OPENTOWN tokens you can vote for the project
            on Discord!
          </p>
          <div
            class="mt-16 content is-flex justify-content-center has-text-justified"
          >
            <ol>
              <li>
                Join SavannaKidz discord channel from
                <a :href="linkToVote" target="_blank">this link.</a>
              </li>
              <li>Go to the <strong>verify-tokens</strong> channel.</li>
              <li>
                Click on <strong>Let's go!</strong> button in the
                <strong>Verify your assets</strong> message.
              </li>
              <li>Click on <strong>Connect wallet</strong>.</li>
              <li>
                Check that you have selected correct address in MetaMask and
                sign the message.
              </li>
              <li>
                Verify that you've received the <strong>$OPENTOWN</strong> role.
              </li>
              <li>Go to the <strong>council-voting</strong> channel.</li>
              <li>Cast a vote on your desired proposal option.</li>
            </ol>
          </div>
          <p>*Votes take some time to be counted, so please be patient.</p>
          <div
            class="is-flex-desktop align-items-center justify-content-center"
          >
            <div>
              <img
                src="~@/assets/img_claim_ot_2.png"
                height="400"
                width="700"
                alt="claim_ot"
                class="is-hidden-mobile"
              />
            </div>
            <a
              :href="linkToVote"
              class="has-bold-border is-block deep-blue-bg-color mt-16 pd-16 has-16-radius"
              target="_blank"
            >
              <img
                src="~@/assets/img_vote.png"
                height="150"
                width="180"
                alt="vote"
              />
              <p class="white-text-color font-size-24">Let's go vote here!</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// UI
import "bulma/css/bulma.css";

import {
  state,
  checkProvider,
  connectWallet,
  getOTBalanceOfAdmin,
  getTotalClaimable,
  getReward,
  accountChangeListeners,
  removeEventListeners,
} from "../utils/ethereum";

export default {
  name: "Claim",
  data: () => ({
    isLoading: true,
    ethState: state,
    linkToVote: "https://discord.gg/tGR4GwnS9S",
  }),
  computed: {
    canClaim() {
      return this.ethState.skTotalClaimable > 0;
    },
  },
  methods: {
    async fetchContractData() {
      this.isLoading = true;
      await getOTBalanceOfAdmin();
      await getTotalClaimable();
      this.isLoading = false;
    },
    async connect() {
      this.isLoading = true;
      await connectWallet();
      await this.fetchContractData();
      this.isLoading = false;
    },
    async claim() {
      this.isLoading = true;
      await getReward();
      this.isLoading = false;
    },
  },
  async mounted() {
    accountChangeListeners.push(this.fetchContractData);
    await checkProvider();
    await this.fetchContractData();
  },
  unmounted() {
    removeEventListeners();
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap");
#app {
  font-family: Lexend Deca, Helvetica, Arial, sans-serif;
  color: $default-text-color;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.common-section-container {
  margin: auto;
  padding: 1rem;

  @media (min-width: $tablet-min-width) {
    max-width: $base-width;
  }
}

.font-size-24 {
  font-size: $font-size-24;
}

.full-height {
  min-height: 100vh;
}
.white-text-color {
  color: $white;
}

// colors
.light-yellow-bg-color {
  background-color: $light_yellow;
}
.deep-blue-bg-color {
  background-color: $deep-blue;
}

// Margins
.mt-4 {
  margin-top: 0.25rem;
}
.mt-16 {
  margin-top: 1rem;
}
.mt-32 {
  margin-top: 2rem;
}
.mt-48 {
  margin-top: 3rem;
}
.mt-128 {
  margin-top: 8rem;
}
.pd-16 {
  padding: 1rem;
}

@media (min-width: $tablet-min-width) {
  .pd-16-pc {
    padding: 1px;
  }
  .mt-80-pc {
    margin-top: 5rem;
  }
}

// flex
.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

//button
.button {
  &.has-bold-border {
    border: 5px solid black;
  }
  &.is-primary:hover,
  .is-primary.is-hovered {
    border: 5px solid black;
  }
}

// border
.has-bold-border {
  border: 5px solid black;
}

// radius
.has-16-radius {
  border-radius: 16px;
}
</style>
