<template>
  <modal :isActive="isActive" @onCloseModal="onCloseModal">
    <template #title>
      <div class="is-flex justify-content-space-between">
        <div class="font-size-24" v-if="isLoading">Processinng...</div>
        <div class="font-size-24" v-else-if="ethState.errorMessage">
          Something went wrong...
        </div>
        <div class="font-size-24" v-else-if="ethState.lastPaymentReceipt">
          Payment complete!
        </div>
        <div class="font-size-24" v-else>
          {{ `Let's ${building.nameWithVerb} !` }}
        </div>
      </div>
    </template>
    <template #contents>
      <div v-if="isLoading" class="is-flex justify-content-center">
        <div>
          <img
            src="@/assets/gif/gif_is_loaging_330px.gif"
            alt="loading kidz"
            height="280"
            width="280"
          />
          <p class="font-size-16 has-text-weight-bold">
            Please confirm transaction in Metamask...
          </p>
        </div>
      </div>
      <p
        class="font-size-16 has-text-weight-bold"
        v-else-if="ethState.errorMessage"
      >
        {{ ethState.errorMessage }}
      </p>

      <div
        v-else-if="ethState.lastPaymentReceipt"
        class="is-flex justify-content-center"
      >
        <div>
          <img
            src="@/assets/gif/gif_complete_330px.gif"
            alt="loading kidz"
            height="280"
            width="280"
          />
          <div class="has-text-weight-bold has-text-centered">
            <p class="font-size-24">Thank you so much!!!😇</p>
            <p class="font-size-16">
              Check transaction <a :href="txURL">here</a>
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="mt-16 has-thin-border has-16-radius pd-16 light-yellow-bg-color"
        >
          <p class="font-size-24">Option 1: Support with NFTs</p>
          <div class="is-flex align-items-center">
            <span>With the proceeds from </span>
            <div>
              <img
                src="@/assets/img_logo.png"
                alt="This image is the logo onf Savanna kidz"
                height="40"
                width="40"
              />
            </div>
            <div class="has-text-weight-bold">
              <span class="ml-8 font-size-32">{{ building.requiredNfts }}</span>
              <span class="ml-8">NFTs</span>
            </div>
          </div>
          <span>{{
            `savanna kidz NFT villagers can ${building.nameWithVerb}!`
          }}</span>
          <div class="is-flex justify-content-center mt-16">
            <primary-button @click="onClickBuyNft" :buttonText="'Buy an NFT'" />
          </div>
        </div>
        <div
          class="light-blue-bg-color has-thin-border has-16-radius pd-16 mt-16"
        >
          <p class="font-size-24">Option 2: Support with ETH</p>

          <div class="is-flex align-items-center">
            <span>If anyone supports with</span>
            <div>
              <img
                src="@/assets/ic_eth.png"
                alt="close icon"
                onClick=""
                height="40"
                width="40"
              />
            </div>
            <p class="has-text-weight-bold">
              <span class="font-size-32">{{ building.requiredEth }}</span>
              <span class="ml-8">ETH</span>
            </p>
          </div>
          <span>{{
            `, the villagers can ${building.nameWithVerb} in no time!`
          }}</span>
          <div class="mt-16 white-bg-color has-16-radius pd-16">
            <p>If you support us directly with ETH, we'll give you...</p>
            <p class="content">
              <ul>
                <li>A special NFT as a token of our appreciation!</li>
                <li>
                  {{ `The naming rights of the facility.` }}
                </li>
              </ul>
            </p>
          </div>

          <div class="is-flex justify-content-center mt-16">
            <div v-if="!ethState.address">
              <!-- 1: Connect wallet -->
              <primary-button
                v-if="ethState.ethereum"
                :buttonIcon="ethIcon"
                :buttonText="'Connect wallet'"
                @click="connect"
              />
              <!-- 2: install metamask -->
              <primary-button
                :buttonText="'Please install MetaMask!'"
                :isDisabled="true"
                v-else
              />
            </div>
            <div v-else>
              <!-- 3: Donate ETH -->
              <primary-button
                :buttonIcon="ethIcon"
                :buttonText="documentButtonText"
                @click="donate"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { ref, defineComponent, onMounted, computed } from "vue";
import { useBuildingData } from "../../logic/useBuildingData";
import {
  state,
  checkProvider,
  connectWallet,
  sendPayment,
} from "../../utils/ethereum";

import Modal from "../Modal.vue";
import PrimaryButton from "../PrimaryButton.vue";

export default defineComponent({
  components: {
    Modal,
    PrimaryButton,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    buildingId: {
      type: Number,
      required: true,
    },
  },

  setup(props, context) {
    const { targetBuilding } = useBuildingData();
    const building = targetBuilding(props.buildingId);
    const isLoading = ref(false);
    const ethState = ref(state);

    onMounted(async () => {
      await checkProvider();
    });

    const txURL = computed(() => {
      if (process.env.VUE_APP_ENV === "production") {
        return `https://etherscan.io/tx/${ethState.value.lastPaymentReceipt.transactionHash}`;
      } else {
        return `https://rinkeby.etherscan.io/tx/${ethState.value.lastPaymentReceipt.transactionHash}`;
      }
    });

    const onCloseModal = () => {
      ethState.value.lastPaymentReceipt = null;
      console.log(ethState.value.lastPaymentReceipt);
      context.emit("onCloseModal", building.id);
    };

    const onClickBuyNft = () => {
      context.emit("onClickBuyNft", building.id);
    };

    const connect = async () => {
      isLoading.value = true;
      await connectWallet();
      isLoading.value = false;
    };

    const donate = async () => {
      if (state.addressBalance >= building.requiredEth) {
        alert(`Thank you, but ${building.displayName} is already funded!`);
      } else {
        isLoading.value = true;
        await sendPayment(building.address, String(building.requiredEth));
        isLoading.value = false;
      }
    };

    return {
      building,
      connect,
      donate,
      txURL,
      isLoading,
      ethState,
      onCloseModal,
      onClickBuyNft,
      ethIcon: require("@/assets/ic_eth.png"),
      documentButtonText: `Donate ${building.requiredEth} ETH`,
    };
  },
});
</script>
