<template>
  <div class="light-yellow-bg-color section-height">
    <div class="common-section-container">
      <div class="is-flex justify-content-space-between">
        <div class="is-flex align-items-center">
          <div class="mr-16">
            <img
              src="~@/assets/img_logo.png"
              width="120"
              height="120"
              alt="logo"
              class="is-hidden-mobile"
            />
            <img
              src="~@/assets/img_logo.png"
              width="44"
              height="44"
              alt="logo"
              class="is-hidden-tablet"
            />
          </div>
        </div>
        <div class="is-flex align-items-center">
          <a class="ml-32-pc mr-16" :href="docsUrl" target="_blank">
            <img
              src="~@/assets/ic_document.png"
              width="48"
              height="48"
              alt="document icon"
            />
          </a>
          <!-- <a href="https://www.savannakidz.com/claim" class="ml-32-pc mr-16">
            <img
              src="~@/assets/ic_ot.png"
              width="48"
              height="48"
              alt="claim icon"
            />
          </a> -->
          <a
            class="ml-32-pc mr-16"
            href="https://twitter.com/savannakidz "
            target="_blank"
          >
            <img
              src="~@/assets/ic_twitter.png"
              width="48"
              height="48"
              alt="twitter logo"
            />
          </a>
          <a
            class="ml-32-pc mr-16"
            href="https://discord.gg/tGR4GwnS9S"
            target="_blank"
          >
            <img
              src="~@/assets/ic_discord.png"
              width="48"
              height="48"
              alt="discord logo"
            />
          </a>
          <!-- <div class="ml-32-pc mr-16">
            <img src='~@/assets/ic_medium.png' width="48" height="48" alt="medium logo" />
          </div> -->
          <a
            class="ml-32-pc"
            href="https://opensea.io/collection/savanna-kidz"
            target="_blank"
          >
            <img
              src="~@/assets/ic_opensea.png"
              width="48"
              height="48"
              alt="opensea logo"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useDocuments } from "../logic/useDocuments";

export default defineComponent({
  setup() {
    const { docsUrl } = useDocuments();

    return {
      docsUrl,
    };
  },
});
</script>
