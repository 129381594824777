export const DOCS_URL = "https://open-town.notion.site/";
export const DOCS_JA_URL = "Savanna-Kidz-NFT-bd57d35a109649d68f4432931544512d";
export const DOCS_EN_URL =
  "open-town/Savanna-Kidz-NFT-English-6b9b7377830843c08c30beae90a23b2d";
export const IDEAS_URL =
  "Ideas-for-Open-Town-in-Uganda-f7e1b9db67254c1383e3b7f611797b62";
export const TERMS_URL = "Terms-Conditions-6ebef548136a424da63554cab0dc09f3";
export const WHAT_IS_OT_URL =
  "What-is-OPENTOWN-8d3f755c0fcf42eaadf2f5b948753790";
export const HOW_TO_MINT = "Useful-Guides-f93e35497be447ae8c2f47955bce42bc";
