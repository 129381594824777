<template>
  <div class="tile is-ancestor">
    <div class="tile is-parent is-vertical is-5">
      <OTMain class="tile is-child box" />
      <OTBurn class="tile is-child box" />
    </div>
    <div class="tile is-parent">
      <OTBalanceChecker class="tile is-child box" />
    </div>
  </div>
</template>

<script>
import OTMain from "./OTMain.vue";
import OTBurn from "./OTBurn.vue";
import OTBalanceChecker from "./OTBalanceChecker.vue";

export default {
  name: "OpenTown",
  components: { OTMain, OTBurn, OTBalanceChecker },
};
</script>
