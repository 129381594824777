import { ref } from "vue";
import buildingsDataJson from "@/assets/data/buildings.json";
import { getAddressEthBalance } from "@/utils/ethereum.js";

export const useBuildingData = () => {
  const BuildingStatusEnum = Object.freeze({
    NOT_YET: "not yet",
    PROCESSING: "processing",
    FINISHED: "finished",
  });

  const FundsStatusEnum = Object.freeze({
    NONE: "none",
    PARTIAL: "partial",
    ALL: "all",
  });

  const targetBuilding = (id) => {
    return buildingsDataJson.find((data) => data.id == id) || {};
  };

  const displayStatusHash = (id) => {
    const building = targetBuilding(id);
    return {
      status: {
        isNone: building.status === BuildingStatusEnum.NOT_YET,
        isProcessing: building.status === BuildingStatusEnum.PROCESSING,
        isFinished: building.status === BuildingStatusEnum.FINISHED,
      },
      funds: {
        hasAllCollected: building.funds === FundsStatusEnum.ALL,
        hasPartialCollected: building.funds === FundsStatusEnum.PARTIAL,
        hasNone: building.funds === FundsStatusEnum.NONE,
      },
    };
  };

  const isBuildingModalActive = ref(false);
  const closeModal = () => {
    isBuildingModalActive.value = false;
  };
  // Call when click a builiding or close dialog
  const handleClick = (id) => {
    const building = targetBuilding(id);

    if (building.address) {
      getAddressEthBalance(building.address);
    }

    switch (building.funds) {
      case FundsStatusEnum.NONE:
      case FundsStatusEnum.PARTIAL:
        isBuildingModalActive.value = !isBuildingModalActive.value;
        break;
      case FundsStatusEnum.ALL:
        console.log("Funds have been already collected.");
        break;
      default:
        isBuildingModalActive.value = false;
        console.log("Unsupported funds status");
    }
  };

  return {
    handleClick,
    closeModal,
    targetBuilding,
    buildingsDataJson,
    displayStatusHash,
    isBuildingModalActive,
  };
};
