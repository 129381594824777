<template>
  <iframe
    :width="width"
    :height="isMobile ? 168 : 315"
    :src="`https://www.youtube.com/embed/${videoId}`"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen />
</template>

<script>
export default {
  name: "Youtube",
  props: {
    videoId: {
      type: String,
      required: true,
    },
    width: {
      default: '100%',
    },
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted () {
    this.isMobile = window.matchMedia && window.matchMedia('(max-width: 769px)').matches
  }
};
</script>
