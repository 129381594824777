<template>
  <FirstView/>
  <AccomplishmentsList  class="pt-64-pc pt-32 pb-80-pc pb-32" />
  <FundsFlowSection class="pt-64-pc pt-32 pb-80-pc pb-32" />
  <Proposals class="pt-64-pc pt-32 pb-80-pc pb-32"  />
  <WhatsNext />
  <Purchase class="pt-64 pb-80-pc pb-64-mobile" />
  <VisionSection class="pt-64-pc pt-32 pb-80-pc pb-32" />
  <CollectionSection class="pt-64-pc pt-32" />
  <TeamSection class="pt-32 pb-80-pc pb-32" />
  <FaqSection class="pt-64-pc pt-32-mobile pb-80-pc pb-32" />
  <Footer />
</template>

<script>
// UI
import "bulma/css/bulma.css";

// Components
import FirstView from "../components/FirstView/FirstView.vue";
import AccomplishmentsList from "../components/Accomplishments/List.vue";
import Proposals from "../components/Proposals.vue";
import WhatsNext from "../components/WhatsNext.vue";
import Purchase from "../components/Purchase.vue";
import VisionSection from "../components/VisionSection.vue";
import FundsFlowSection from "../components/FundsFlowSection.vue";
import CollectionSection from "../components/CollectionSection.vue";
import TeamSection from "../components/TeamSection.vue";
import FaqSection from "../components/FaqSection.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "App",
  components: {
    FirstView,
    AccomplishmentsList,
    Proposals,
    WhatsNext,
    Purchase,
    VisionSection,
    FundsFlowSection,
    CollectionSection,
    TeamSection,
    FaqSection,
    Footer,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap");
#app {
  font-family: Lexend Deca, Helvetica, Arial, sans-serif;
  color: $default-text-color;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.common-section-container {
  margin: auto;
  padding: 1rem;

  @media (min-width: $tablet-min-width) {
    max-width: $base-width;
  }
}

.full-height {
  min-height: 100vh;
}

// text
.font-size-12 {
  font-size: $font-size-12;
}
.font-size-16 {
  font-size: $font-size-16;
}
.font-size-20 {
  font-size: $font-size-20;
}
.font-size-24 {
  font-size: $font-size-24;
}
.font-size-32 {
  font-size: $font-size-32;
}
.font-size-36 {
  font-size: $font-size-36;
}
.font-size-48 {
  font-size: $font-size-48;
}
.font-size-56 {
  font-size: $font-size-56;
}

// text-style
.bold{
  font-weight: 600;
}
.inverted{
  color: #fff;
}
.link{
  color: $primary;
  text-decoration-line: underline;
  font-size: $font-size-24;
}

.has-new-line {
  white-space: pre-line;
}
.white-edge-4 {
  text-shadow: 4px 4px 0 #fff, -4px -4px 0 #fff, -4px 4px 0 #fff,
    4px -4px 0 #fff, 0px 4px 0 #fff, 0-4px 0 #fff, -4px 0 0 #fff, 4px 0 0 #fff;
}
.white-edge-2 {
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
}

// text-color
.default-text-color {
  color: $default-text-color;
}

.alert-text-color {
  color: $alert-red;
}

.grey600-text-color {
  color: $grey600;
}

.white-text-color {
  color: $white;
}

.deep-blue-text-color {
  color: $deep-blue;
}

// @media (min-width) does not work in production build
@media (max-width: $tablet-min-width) {
  .font-size-16-mobile {
    font-size: $font-size-16;
  }
  .font-size-24-mobile {
    font-size: $font-size-24;
  }
  .font-size-36-mobile {
    font-size: $font-size-36;
  }

  .pd-16-mobile {
    padding: 1rem;
  }

  .pt-32-mobile {
    padding-top: 2rem;
  }

  .pb-64-mobile {
    padding-bottom: 4rem;
  }

  .mt-8-mobile {
    margin-top: 0.5rem;
  }
  .mt-16-mobile {
    margin-top: 1rem;
  }
  .mt-32-mobile {
    margin-top: 2rem;
  }
  .mb-16-mobile {
    margin-bottom: 1rem;
  }
  .mr-16-mobile {
    margin-right: 1rem;
  }
  .ml-16-mobile {
    margin-left: 1rem;
  }
}

@media (min-width: $tablet-min-width) {
  .font-size-12-pc {
    font-size: $font-size-12;
  }
  .font-size-16-pc {
    font-size: $font-size-16;
  }
  .font-size-20-pc {
    font-size: $font-size-20;
  }
  .font-size-24-pc {
    font-size: $font-size-24;
  }
  .font-size-36-pc {
    font-size: $font-size-36;
  }
  .font-size-48-pc {
    font-size: $font-size-48;
  }
  .font-size-64-pc {
    font-size: $font-size-64;
  }
  .font-size-72-pc {
    font-size: $font-size-72;
  }
}

// colors
.light-blue-bg-color {
  background-color: $light-blue;
}
.light-yellow-bg-color {
  background-color: $light_yellow;
}
.light-green-bg-color {
  background-color: $light_green;
}
.grey50-bg-color {
  background-color: $grey50;
}
.deep-green-bg-color {
  background-color: $deep-green;
}
.white-bg-color {
  background-color: $white;
}
.middle-green-bg-color {
  background-color: $middle-green;
}
.middle-blue-bg-color {
  background-color: $middle-blue;
}

// for bulma related components
.no-margin {
  margin: 0;
  margin-bottom: 0 !important;
}
.no-padding {
  padding: 0;
}

// Margins
.mt-2 {
  margin-top: 0.125rem;
}
.mt-4 {
  margin-top: 0.25rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mt-12 {
  margin-top: 0.75rem;
}
.mt-16 {
  margin-top: 1rem;
}
.mt-24 {
  margin-top: 1.5rem;
}
.mt-32 {
  margin-top: 2rem;
}
.mt-48 {
  margin-top: 3rem;
}
.mt-128 {
  margin-top: 8rem;
}

@media (min-width: $tablet-min-width) {
  .mt-0-pc {
    margin-top: 0rem;
  }
  .mt-8-pc {
    margin-top: 0.5rem;
  }
  .mt-32-pc {
    margin-top: 2rem;
  }
  .mt-48-pc {
    margin-top: 3rem;
  }
  .mt-80-pc {
    margin-top: 5rem;
  }
  .mt-128-pc {
    margin-top: 8rem;
  }
}

.ml-8 {
  margin-left: 0.5rem;
}
.ml-16 {
  margin-left: 1rem;
}
.ml-32 {
  margin-left: 2rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.mr-16 {
  margin-right: 1rem;
}
.mr-32 {
  margin-right: 2rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.mb-24 {
  margin-bottom: 1.5rem;
}
.mb-32 {
  margin-bottom: 2rem;
}

@media (min-width: $tablet-min-width) {
  .mb-24-pc {
    margin-bottom: 1.5rem;
  }
  .mb-32-pc {
    margin-bottom: 2rem;
  }
}

@media (min-width: $tablet-min-width) {
  .ml-8-pc {
    margin-left: 0.5rem;
  }
  .ml-12-pc {
    margin-left: 0.75rem;
  }
  .ml-16-pc {
    margin-left: 1rem;
  }
  .ml-32-pc {
    margin-left: 2rem;
  }
  .mr-8-pc {
    margin-right: 0.5rem;
  }
  .mr-16-pc {
    margin-right: 1rem;
  }
  .mr-32-pc {
    margin-right: 2rem;
  }
}

// Paddings
.pd-4 {
  padding: 0.25rem;
}
.pd-8 {
  padding: 0.5rem;
}
.pd-16 {
  padding: 1rem;
}
.pd-24 {
  padding: 1.5rem;
}
.pd-32 {
  padding: 2rem;
}
.pd-48 {
  padding: 3rem;
}
.pt-32 {
  padding-top: 2rem;
}
.pt-48 {
  padding-top: 3rem;
}
.pt-64 {
  padding-top: 4rem;
}
.pb-32 {
  padding-bottom: 2rem;
}
.pb-48 {
  padding-bottom: 3rem;
}
.pb-64 {
  padding-bottom: 4rem;
}
.pl-16 {
  padding-left: 1rem;
}
.pl-24 {
  padding-left: 1.5rem;
}
.pl-32 {
  padding-left: 2rem;
}
.pr-16 {
  padding-right: 1rem;
}
.pr-24 {
  padding-right: 1.5rem;
}
.pr-32 {
  padding-right: 2rem;
}

@media (min-width: $tablet-min-width) {
  .pd-0-pc {
    padding: 0px;
  }
  .pd-8-pc {
    padding: 0.5rem;
  }
  .pd-16-pc {
    padding: 1rem;
  }
  .pd-8-pc {
    padding: 0.5rem;
  }
  .pd-24-pc {
    padding: 1.5rem;
  }
  .pd-32-pc {
    padding: 2rem;
  }
  .pd-48-pc {
    padding: 3rem;
  }
  .pt-64-pc {
    padding-top: 4rem;
  }
  .pt-80-pc {
    padding-top: 5rem;
  }
  .pb-80-pc {
    padding-bottom: 5rem;
  }
}

.full-width {
  width: 100%;
}

// flex
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-start {
  align-items: start;
}

.is-flex {
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-space-around {
    justify-content: space-around;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }
}

// header
.has-header-shade {
  background: -moz-linear-gradient(top, #e3fcff 40%, rgba(255, 255, 255, 0));
  background: -webkit-linear-gradient(top, #e3fcff 40%, rgba(255, 255, 255, 0));
  background: linear-gradient(to bottom, #e3fcff 40%, rgba(255, 255, 255, 0));
}

//button
.button {
  &.has-bold-border {
    border: 5px solid black;
  }
  &.is-primary:hover,
  .is-primary.is-hovered {
    border: 5px solid black;
  }
  &.is-info:hover,
  .is-info.is-hovered {
    border: 5px solid black;
  }
  &.is-warning:hover,
  .is-warning.is-hovered {
    border: 5px solid black;
  }
}

// radius
.has-16-radius {
  border-radius: 16px;
}

// border
.has-bold-border {
  border: 5px solid black;
}
.has-thin-border {
  border: 2px solid black;
}
</style>
