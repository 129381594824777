<template>
  <div class="light-yellow-bg-color section-height">
    <div class="common-section-container">
      <h3 class="font-size-36-pc font-size-24-mobile">
        Participate in the creation of a self-sustaining decentralized city in the real world by owning Savanna Kidz NFTs
      </h3>
      <div class="mt-32-pc mt-16 is-flex-desktop">
        <div class="box-width">
          <div class="is-flex justify-content-center img-vision">
            <img
              src="~@/assets/img_vision_1.png"
              height="320"
              width="312"
              alt="vision"
            />
          </div>
          <p class="font-size-24-pc font-size-16 has-text-centered mt-16">
            This is Faith Angels Ministry, a small elementary school and
            orphanage in Uganda.
          </p>
          <div class="has-text-centered mt-16 has-text-centered mt-16">
            <primary-button
              :linkUrl="linkToMap"
              :buttonIcon="locationIcon"
              :buttonText="locationIconButtonText" />
          </div>
        </div>
        <div class="is-flex justify-content-center arrow-width mt-8 mb-8">
          <img
            src="~@/assets/ic_arrow_right.svg"
            height="48"
            width="48"
            alt="vision"
            class="is-hidden-mobile"
          />
          <img
            src="~@/assets/ic_arrow_down.svg"
            height="48"
            width="48"
            alt="vision"
            class="is-hidden-tablet"
          />
        </div>
        <div class="box-width">
          <div class="is-flex justify-content-center img-vision">
            <img
              src="~@/assets/img_vision_2.png"
              height="304"
              width="380"
              alt="vision"
            />
          </div>
          <p class="font-size-24-pc font-size-16-mobile has-text-centered mt-16">
            NFT ownership allows you to join the community, an autonomous and democratic organization made by NFT holders,
            where you will be able to propose ideas and vote to actively participate in the development of the city, for instance, by providing potable water.
          </p>
        </div>
      </div>
      <div class="is-flex justify-content-center mt-16">
        <img
          src="~@/assets/ic_arrow_diagonal.svg"
          height="48"
          width="48"
          alt="vision"
          class="is-hidden-mobile"
        />
        <img
          src="~@/assets/ic_arrow_down.svg"
          height="48"
          width="48"
          alt="vision"
          class="is-hidden-tablet"
        />
      </div>
      <div>
        <div class="is-flex justify-content-center">
          <img
            src="~@/assets/img_vision_3.png"
            height="320"
            width="780"
            alt="vision"
          />
        </div>
        <p class="font-size-24-pc has-text-centered mt-16">
          On top of that, the villagers will have access to better education and improved
          quality of life, leading to a more progressive community!
        </p>
      </div>
      <div class="is-flex justify-content-center mt-16">
        <img
          src="~@/assets/ic_arrow_down.svg"
          height="48"
          width="48"
          alt="vision"
        />
      </div>
      <div>
        <div class="is-flex justify-content-center">
          <img
            src="~@/assets/img_vision_4.png"
            height="320"
            width="780"
            alt="vision"
          />
        </div>
        <p class="font-size-24-pc font-size-16 has-text-centered mt-16">
          This is just the beginning.
          More cities will be developed all over the world under what we call The Open Town project,
          thanks to the active participation of Savanna Kidz NFT owners!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "../components/PrimaryButton.vue";
export default {
  components: { PrimaryButton },
  data() {
    return {
      linkToMap: "https://goo.gl/maps/h3wgiBdFrWjM41QD7",
      locationIcon: require('@/assets/ic_location_pink.png'),
      locationIconButtonText: "Faith Angels Ministry"
    };
  },
};
</script>

<style lang="scss" scoped>
.box-width {
  flex: 4;
}
.arrow-width {
  flex: 2;
}
_::-webkit-full-page-media,
_:future,
:root .img-vision {
  margin-bottom: 3rem;
}
</style>
