<template>
  <div :class="isLoading ? 'is-disabled' : ''">
    <div class="field">
      <label class="label">Check token balance of address</label>
      <div class="columns">
        <div class="column">
          <div class="control">
            <input
              class="input"
              type="text"
              @change="balanceOf"
              v-model="skBalanceCheckAddr"
              placeholder="Ethereum address"
            />
          </div>
        </div>
        <div class="column is-one-fifth">
          <div class="control">
            <input
              class="input"
              type="number"
              disabled="true"
              v-model="ethState.skBalanceOf"
              placeholder="Balance"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="field mt-4">
      <label class="label">Check owner of a token</label>
      <div class="columns">
        <div class="column is-one-fifth">
          <div class="control">
            <input
              class="input"
              type="number"
              min="1"
              max="10000"
              @change="ownerOf"
              v-model="skOwnerCheckTokenId"
              placeholder="Token ID"
            />
          </div>
        </div>
        <div class="column">
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="ethState.skOwnerOf"
              disabled="true"
              placeholder="Owner address"
            />
          </div>
        </div>
      </div>
    </div>
    <button
      @click="$refs.viewer.show()"
      class="button is-primary is-fullwidth"
      :disabled="!skOwnerCheckTokenId"
    >
      Show NFT
    </button>
    <NFTViewer ref="viewer" :tokenID="skOwnerCheckTokenId" />
  </div>
</template>

<script>
import NFTViewer from "./NFTViewer.vue";

import {
  state,
  getSKBalaceOf,
  getSKOwnerOf,
  getTokenURIOf,
} from "../../utils/ethereum";

export default {
  name: "SKBalanceChecker",
  components: { NFTViewer },
  data: () => ({
    isLoading: false,
    ethState: state,
    skBalanceCheckAddr: null,
    skOwnerCheckTokenId: null,
  }),
  methods: {
    async balanceOf() {
      this.isLoading = true;
      await getSKBalaceOf(this.skBalanceCheckAddr);
      this.isLoading = false;
    },
    async ownerOf() {
      this.isLoading = true;
      await getSKOwnerOf(this.skOwnerCheckTokenId);
      await getTokenURIOf(this.skOwnerCheckTokenId);
      this.isLoading = false;
    },
  },
};
</script>
