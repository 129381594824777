<template>
  <div class='middle-blue-bg-color pt-32 pl-32 pr-32'>
    <div class="common-section-container">
      <h2 class='font-size-48'>
        What's next to improve?
      </h2>
      <div class='font-size-24'>
        Here you can see the progress of the development of Karungu Village. Gray buildings represents facilities that are still missing.
      </div>
    </div>
  </div>
  <div class="middle-green-bg-color pb-32 relative">
    <div class="map-section-height map-view-image " />
    <img class="sun" src="~@/assets/gif/gif_sun.gif" alt="gif sun" />
    <div class="cloud is-flex">
      <img src="~@/assets/desktop/facility/effect/img_cloud.png" alt="cloud" />
      <img src="~@/assets/desktop/facility/effect/img_cloud.png" alt="cloud" />
    </div>

    <div v-for="data of buildingsDataJson" :key="data.id">
      <component
        :is="data.slug"
        :data="displayStatusHash(data.id)"
        @click="openModal(data.id)"
        :isColoredFacility="targetBuildingId == data.id"
      />
    </div>
    <div ref="refInfo" class="refInfoPosition" />
    <building-modal
      v-if="targetBuildingId > 0"
      :buildingId="targetBuildingId"
      :isActive="isBuildingModalActive"
      @onCloseModal="closeModal"
      @onClickBuyNft="openMinter"
    />
  </div>
</template>

<script>
import WaterTank from "./WaterTank.vue";
import Bank from "./Bank.vue";
import Bus from "./Bus.vue";
import School from "./School.vue";
import SolarPower from "./SolarPower.vue";
import Potato from "./Potato.vue";
import Animals from "./Animals.vue";
import Info from "../Info.vue";
import BuildingModal from "./BuildingModal.vue";
import PrimaryButton from "../PrimaryButton.vue";

import { ref, defineComponent } from "vue";
import { useBuildingData } from "../../logic/useBuildingData";
import { useMinter } from "../../logic/useMinter";

export default defineComponent({
  components: {
    WaterTank,
    Bank,
    Bus,
    School,
    SolarPower,
    Potato,
    Animals,
    BuildingModal,
    Info,
    PrimaryButton,
  },
  setup() {
    const {
      displayStatusHash,
      buildingsDataJson,
      isBuildingModalActive,
      handleClick,
    } = useBuildingData();

    const { open } = useMinter();

    const refInfo = ref(null);
    const targetBuildingId = ref(-1);

    const openModal = (id) => {
      targetBuildingId.value = id;
      handleClick(id);
    };

    const closeModal = (id) => {
      targetBuildingId.value = -1;
      handleClick(id);
    };

    const openMinter = () => {
      open()
    };

    return {
      refInfo,
      handleClick,
      displayStatusHash,
      buildingsDataJson,
      isBuildingModalActive,
      openModal,
      closeModal,
      openMinter,
      targetBuildingId,
    };
  },
});
</script>

<style lang="scss" scoped>
.map-section-height {
  position: relative;
  background-image: url("~@/assets/desktop/img_map_background_1150px.png");
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  background-position: top;
}

.cloud {
  width: 100vw;
  height: auto;
  overflow: hidden;
  position: absolute;
  top: calc(4vh + 16px);
  left: 0;
}

.cloud img {
  height: 16vh;
  width: 100vw;
}
@keyframes loop {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes loop2 {
  0% {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0);
  }
}
.cloud img:first-child {
  animation: loop 80s -40s linear infinite;
}

.cloud img:last-child {
  animation: loop2 80s linear infinite;
}

.dialog {
  position: absolute;
  z-index: $modal-lay;
}

.info {
  @media (min-width: $tablet-min-width) {
    max-width: $base-width;
    margin: auto;
  }
}
.refInfoPosition {
  position: absolute;
  top: 60%;
}

.sun {
  position: absolute;
  top: calc(4vh + 48px);
  right: 4vw;
  width: 10vw;
}

@media (min-width: calc($pc-min-width - 1px)) {
  .map-section-height {
    height: 100vh;
    width: 100vw;
  }
  .map-section-height {
    background-image: url("~@/assets/desktop/img_map_background_1150px.png");
  }
}
@media (min-width: $widescreen-min-width) {
  .map-section-height {
    height: 100vh;
  }
  .map-view-image {
    background-image: url("~@/assets/desktop/img_map_background_1150px.png");
  }
}

@media (min-width: $fullwidth-min-width) {
  .map-section-height {
    height: 100vh;
  }
  .map-view-image {
    background-image: url("~@/assets/desktop/img_map_background_1950px.png");
  }
}

.relative{
  position: relative;
}
</style>
