<template>
  <div class="tile is-ancestor">
    <div class="tile is-parent is-vertical is-5">
      <SKMain class="tile is-child box" />
      <SKSale class="tile is-child box" />
      <SKWithdraw class="tile is-child box" />
    </div>
    <div class="tile is-parent is-vertical">
      <SKBalanceChecker class="tile is-child box" />
      <SKAirdrop class="tile is-child box" />
    </div>
  </div>
</template>

<script>
import SKMain from "./SKMain.vue";
import SKSale from "./SKSale.vue";
import SKWithdraw from "./SKWithdraw.vue";
import SKBalanceChecker from "./SKBalanceChecker.vue";
import SKAirdrop from "./SKAirdrop.vue";

export default {
  name: "SavannaKidz",
  components: { SKMain, SKSale, SKWithdraw, SKBalanceChecker, SKAirdrop },
};
</script>
