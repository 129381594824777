<template>
  <div>
    <div class="is-flex justify-content-center" v-if="isLoading">
      Loading...
    </div>

    <div v-else-if="!ethState.address">
      <!-- 1: Connect wallet -->
      <primary-button
        @click="connect"
        v-if="ethState.ethereum"
        :buttonText="'Connect wallet'"
        :isDisabled="isLoading"
      />
      <!-- 2: install metamask -->
      <primary-button
        :buttonText="'Please install MetaMask!'"
        :isDisabled="true"
        v-else
      />
    </div>

    <div v-else-if="isSaleOngoing">
      <!-- 3: sold out -->
      <div v-if="!canMint" class="notification is-warning">
        <p class="font-size-24 alert-text-color">
          Sorry, but all Savanna Kidz are
          <strong class="alert-text-color">sold out!</strong>
        </p>
      </div>
      <div v-else>
        <div v-if="ethState.skIsPresaleActive">
          <!-- 4: Presale header -->
          <div v-if="presaleAllowance" class="mb-4">
            Welcome {{ presaleDiscordHandle }}! You own
            {{ ethState.skBalanceOf }} NFTs and can mint up to
            {{ presaleAllowance.maxMintAmount }} Savanna Kidz for this address
            during presale!
          </div>
          <!-- 5: wrong address -->
          <div v-else>
            <p class="font-size-24">
              Sorry, but your address is not registered for a presale.
            </p>
            <div class="font-size-16 mb-16">
              <p class="mt-4">
                Connected address: <strong>{{ ethState.address }}</strong>
              </p>
              <ul class="mt-4">
                <li>
                  Make sure that you are connected with a correct address.
                </li>
                <li>
                  If your address is correct and you believe that you should've
                  been registered, please contact us on our Discord channel.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Common controls -->
        <!-- 6:available to buy-->
        <div
          v-if="
            ethState.skIsSaleActive ||
            (presaleAllowance && canMintMoreDuringPresale)
          "
        >
          <p>
            <span class="content is-large">{{ remainingTokens }}</span>
            NFTs are still available!
          </p>

          <div class="columns mt-4">
            <div class="column">
              <div class="field">
                <label class="label">Mint to</label>
                <div class="control">
                  <div class="select is-info is-rounded is-small">
                    <select v-model="ethState.address">
                      <option
                        v-for="account in ethState.accounts"
                        v-bind:value="account"
                        v-bind:key="ethState.accounts.indexOf(account)"
                      >
                        {{ account }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="has-text-right">
                <div class="tag is-info">
                  {{ chainName }}
                </div>
              </div>
            </div>
          </div>
          <div class="columns mt-4 white-bg-color has-16-radius">
            <div
              class="column is-flex justify-content-center align-items-center"
            >
              <primary-button
                @click="mint"
                v-if="ethState.ethereum"
                :buttonText="'Mint!'"
                :isDisabled="!canMintSelected"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="notification is-warning">
      <p class="font-size-24">Outside sale period.</p>
    </div>
  </div>

  <!-- Dialogs -->
  <div class="modal" :class="ethState.errorMessage ? 'is-active' : ''">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Something is not right...</p>
      </header>
      <section class="modal-card-body">
        <p class="notification is-danger">{{ ethState.errorMessage }}</p>
        <p>Please reload page and try again.</p>
      </section>
    </div>
  </div>

  <div class="modal" :class="isMinting ? 'is-active' : ''">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Almost there!</p>
      </header>
      <section class="modal-card-body">
        <div class="is-flex justify-content-center align-items-center">
          <img
            src="@/assets/gif/gif_is_loaging_330px.gif"
            alt="loading kidz"
            height="160"
            width="160"
          />
          <div class="has-text-weight-bold">
            <p class="font-size-24">Minting your tokens...</p>
            <p class="font-size-16">
              Please continue the process in MetaMask, then wait until your
              transaction is confirmed.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div class="modal" :class="hasMinted ? 'is-active' : ''">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Minting successful!</p>
      </header>
      <section class="modal-card-body">
        <div class="is-flex justify-content-center align-items-center">
          <img
            src="@/assets/gif/gif_complete_330px.gif"
            alt="loading kidz"
            height="160"
            width="160"
          />
          <div class="has-text-weight-bold">
            <p class="font-size-24">Congratiulations!</p>
            <p class="font-size-16">
              You have successfully minted {{ mintCount }} Savanna Kidz!!
            </p>
          </div>
        </div>
        <div class="is-flex mt-48-pc mt-16 align-items-center">
          <img
            src="~@/assets/ic_opensea.svg"
            height="32"
            width="32"
            alt="opensea"
            class="mr-8"
          />
          <p class="font-size-24">Check your tokens on OpenSea!</p>
        </div>
        <a
          :href="`${openSeaURL}${ethState.address}`"
          class="mt-8 is-block button is-rounded is-medium is-info has-bold-border"
          target="_blank"
          >Check on OpenSea</a
        >

        <p class="has-text-right mt-2 font-size-16">
          * It may take a while before your tokens appear on OpenSea
        </p>

        <div class="is-flex mt-48-pc mt-32 align-items-center">
          <img
            src="~@/assets/ic_ot.png"
            height="32"
            width="32"
            alt="ot icon"
            class="mr-8"
          />
          <p class="font-size-24">
            Go to the Claims page to get your $OPENTOWN token!
          </p>
        </div>
        <!-- <a
          href="https://www.savannakidz.com/claim"
          class="mt-16 is-block button is-rounded is-medium is-warning has-bold-border"
          target="_blank"
          >Claim $OPENTOWN</a
        > -->
        <div class="has-text-centered mt-16">
          <a
            class="button is-danger is-inverted"
            aria-label="close"
            @click="hasMinted = false"
            >Close</a
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import {
  state,
  checkProvider,
  connectWallet,
  getSKSupply,
  getSaleStatus,
  getSKBalaceOf,
  getPresaleStatus,
  mintSK,
  presaleMintSK,
  removeEventListeners,
  accountChangeListeners,
} from "../utils/ethereum";

const TOKEN_COUNT = 10000;
const NFT_PRICE = "50000000000000000";
import PrimaryButton from "./PrimaryButton.vue";

export default {
  name: "Mint",
  components: {
    PrimaryButton,
  },
  props: {
    mintCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      ethState: state,
      isMinting: false,
      hasMinted: false,
      presaleAllowance: null,
      presaleDiscordHandle: null,
    };
  },
  computed: {
    chainName() {
      switch (this.ethState.chainId) {
        case 1:
          return "Ethereum Main Network (Mainnet)";
        case 4:
          return "Rinkeby Test Network";
        case 1337:
          return "Ganache";
        default:
          return "Unsupported chain.";
      }
    },
    remainingTokens() {
      return TOKEN_COUNT - this.ethState.skSupply;
    },
    isSaleOngoing() {
      return this.ethState.skIsPresaleActive || this.ethState.skIsSaleActive;
    },
    canMint() {
      return this.remainingTokens > 0;
    },
    remainingPresaleAllowance() {
      if (!this.presaleAllowance) return 0;
      return this.presaleAllowance.maxMintAmount - this.ethState.skBalanceOf;
    },
    canMintMoreDuringPresale() {
      return this.remainingPresaleAllowance > 0;
    },
    maxPerMint() {
      return this.ethState.skIsPresaleActive
        ? this.remainingPresaleAllowance
        : 20;
    },
    canMintSelected() {
      return (
        this.mintCount > 0 &&
        this.mintCount <= Math.min(this.maxPerMint, this.remainingTokens)
      );
    },
    openSeaURL() {
      if (process.env.VUE_APP_ENV === "production") {
        return "https://opensea.io/";
      } else {
        return "https://testnets.opensea.io/";
      }
    },
  },
  methods: {
    async connect() {
      this.$gtm.trackEvent({
        category: "purchase",
        action: "connect_wallet",
        label: "ethereum",
        value: this.mintPrice,
      });
      this.isLoading = true;
      await connectWallet();
      this.isLoading = false;
    },
    async fetchContractData() {
      this.isLoading = true;
      await getSKSupply();
      await getSKBalaceOf(this.ethState.address);
      await getSaleStatus();
      await getPresaleStatus();
      this.isLoading = false;
    },
    async mint() {
      this.$gtm.trackEvent({
        category: "purchase",
        action: "mint",
        label: "ethereum",
        value: this.mintPrice,
      });
      this.isMinting = true;
      if (this.ethState.skIsSaleActive) {
        await mintSK(this.mintCount, NFT_PRICE * this.mintCount);
      } else if (this.ethState.skIsPresaleActive) {
        await presaleMintSK(
          this.presaleAllowance,
          this.mintCount,
          NFT_PRICE * this.mintCount
        );
      }

      this.isMinting = false;

      if (!this.ethState.errorMessage) {
        await this.fetchContractData();
        await this.getPresaleAllowance();
        this.hasMinted = true;
      }
    },
    dbServiceURL(address) {
      if (process.env.VUE_APP_ENV === "production") {
        return "";
      } else {
        return `https://asia-northeast1-open-town-nft-staging.cloudfunctions.net/savannaKidzGetWhiteListStaging/white_lists/find/?wallet_address=${address}`;
      }
    },
    async getPresaleAllowance() {
      if (!this.ethState.skIsPresaleActive) return;

      this.presaleAllowance = null;
      this.isLoading = true;
      try {
        const res = await axios.get(this.dbServiceURL(this.ethState.address), {
          data: {},
        });

        this.presaleAllowance = {
          maxMintAmount: res.data[0].max_mint_amount,
          signature: res.data[0].access_code,
        };
        this.presaleDiscordHandle = res.data[0].discord_uid;
      } catch (e) {
        this.presaleAllowance = null;
        this.presaleDiscordHandle = null;
      }

      this.isLoading = false;
    },
  },
  async mounted() {
    accountChangeListeners.push(this.fetchContractData);
    accountChangeListeners.push(this.getPresaleAllowance);
    await checkProvider();
    await this.fetchContractData();
    await this.getPresaleAllowance();
  },
  unmounted() {
    removeEventListeners();
  },
};
</script>
