<template>
  <div class="has-bold-border has-16-radius light-yellow-bg-color pd-24-pc">
    <div>
      <p class="font-size-24 font-size-32 has-text-weight-bold">
        Buy a Savanna Kidz NFT
      </p>
      <p class="font-size-16 mt-16">
        Proceeds from Savanna Kidz NFT sales will be used to improve an Ugandan
        village infrastructure.
      </p>
      <p class="mt-8 pd-8 is-flex align-items-center">
        <img
          src="~@/assets/ic_question.svg"
          height="24"
          width="24"
          alt="How to buy"
        />
        <a
          class="ml-8 default-text-color is-underlined"
          :href="howToMintURL"
          target="_blank"
        >
          How to buy?</a
        >
      </p>
    </div>
    <div class="has-16-radius white-bg-color pd-16">
      <p class="has-text-weight-bold">1.Select Quantity</p>
      <div class="columns mt-8">
        <div class="column is-flex align-items-center">
          <span>Buy</span>
          <div class="field">
            <div class="control ml-4">
              <input
                v-model="mintCount"
                class="input is-rounded"
                type="number"
                min="1"
                :max="maxPerMint"
              />
            </div>
          </div>
          <span class="ml-4">NFT</span>
        </div>
        <p class="column">
          <span class="has-text-weight-bold">Total price</span>
          <span class="ml-16 font-size-24">{{ mintPrice }} ETH</span>
        </p>
      </div>
    </div>
    <div class="white-bg-color pd-16 mt-32 has-16-radius">
      <p class="has-text-weight-bold">2.Select Payment method</p>
      <div class="columns justify-content-center pd-16">
        <div class="column has-16-radius pd-16 has-thin-border mr-8-pc mt-16">
          <p class="font-size-24 mb-16">Buy with ETH</p>
          <Mint :mintCount="mintCount" />
        </div>
        <div class="column pd-16 ml-8-pc has-16-radius has-thin-border mt-16">
          <p class="font-size-24">Buy with Credit card</p>
          <div class="mt-8">
            <div class="is-flex justify-content-center mt-16">
              <crossmint :mintCount="mintCount" :mintPrice="mintPrice" />
            </div>
            <p class="grey600-text-color mt-16">
              Credit card payments can be made through the crossmint platform.
              Please note that a credit card processing fee will be charged in
              addition to the main price and gas fee.
            </p>
            <p class="mt-8">
              <span>If you already purchased Savanna Kidz NFTs</span>
              <a class="pd-8" :href="crossMintCollectionUrl" target="_blank"
                ><span class="is-underlined default-text-color"
                  >click here</span
                ></a
              >
              <span>to check your collection.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mint from "./Mint.vue";
import { DOCS_URL, HOW_TO_MINT } from "../config";
import Crossmint from "./Crossmint.vue";
export default {
  components: {
    Mint,
    Crossmint,
  },
  data() {
    return {
      howToMintURL: DOCS_URL + HOW_TO_MINT,
      mintCount: 1,
      maxPerMint: 20,
    };
  },
  computed: {
    mintPrice() {
      const price = 0.05 * this.mintCount;
      return Math.round((price + Number.EPSILON) * 100) / 100;
    },
    crossMintCollectionUrl() {
      const subdomain =
        process.env.VUE_APP_ENV === "production" ? "www" : "staging";
      return `https://${subdomain}.crossmint.io/signin?callbackUrl=https%3A%2F%2F${subdomain}.crossmint.io%2Fuser%2Fcollection`;
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: $tablet-min-width) {
  .has-bold-border {
    border: 5px solid $light_yellow;
  }
}
</style>
