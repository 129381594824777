<template>
  <div class='background'>
    <div class='common-section-container'>

      <h3 class="font-size-48-pc font-size-36-mobile bold mb-24">
        What Savanna Kidz has accomplished
      </h3>

      <div v-for='(item, index) in items' :key='index'>

        <div :class="['accomp pd-32 pd-16-mobile', { 'mb-32': !item.isLast }]">
          <div class='columns no-margin item-header-container'>

            <div class='img-icon mr-16 item-header-icon'>
              <img :src="item.icon" alt="" />
            </div>

            <div class='item-header-content'>
              <div class='font-size-36-pc font-size-24-mobile mb-16 bold'>{{ item.title }}</div>
              <div class='font-size-24-pc font-size-16-mobile bold mb-8'>{{ item.date }}</div>
              <div class='font-size-24-pc font-size-16-mobile bold'>
                {{ item.subTitle }}
              </div>
            </div>
          </div>

          <div class='font-size-20-pc font-size-16-mobile mb-16 pre-line'>
            {{ item.description }}
          </div>

          <div class='columns tweet'>
            <img class='img-twitter' src="~@/assets/ic_twitter.png" alt="">
            <a class='link font-size-16-mobile ml-8'
              :href="item.tweet"
              target="_blank">
              See tweets of the completion
            </a>
          </div>

          <div class='main-media-container mb-32'>
            <div v-if="item.videoId" class='main-media'>
              <Youtube videoId='1WR9vGgSxs0'/>
            </div>
            <div v-else class='main-media'>
              <img :src="item.mainImage" alt="" />
            </div>
          </div>

          <div class='columns'>

            <div class='column is-4'>
              <div class='sub-image'>
                <div class='font-size-24 font-size-16-mobile bold'>
                  Before
                </div>
                <img :src="item.before" alt="" />
              </div>
            </div>

            <div class='column is-4'>
              <div class='sub-image'>
                <div class='font-size-24 font-size-16-mobile bold'>
                  Under construction
                </div>
                <img :src="item.underConstruction" alt="" />
              </div>
            </div>

            <div class='column is-4'>
              <div class='sub-image'>
                <div class='font-size-24 font-size-16-mobile bold'>
                  After
                </div>
                <img :src="item.after" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Youtube from '@/components/Youtube.vue'
export default {
  name: 'Accomplishment',
  components: {
    Youtube
  },
  data () {
    return {
      items: [
        {
          icon: require('@/assets/img_watertank_72px.png'),
          title: '1st project',
          date: 'March to May 2022',
          subTitle: 'Installation of water storage tanks in villages without running water',
          description: `Karungu Village had no well or water storage tank, and village children had to walk 12 km along a mountain path to fetch water from the river every day.
The constructed rainwater storage tanks are made of concrete and can be used for more than 20 years with regular inspections and repairs.`,
          before: require('@/assets/img_1st_project_before.png'),
          underConstruction: require('@/assets/img_1st_project_under_construction.png'),
          after: require('@/assets/img_1st_project_after.png'),
          videoId: '1WR9vGgSxs0',
          tweet: 'https://twitter.com/savannakidz/status/1529339615416811520?s=20&t=Axg08OpXUCTZgkff_D7CoQ'
        },

        {
          icon: require('@/assets/img_school_80.png'),
          title: '2nd project',
          date: 'August to November 2022',
          subTitle: 'Construction of a new school building for the village\'s elementary school',
          description: `The classrooms were previously temporary and had no windows, doors, or floors. As a result, children often suffered from physical discomfort due to the cold and other weather conditions.
So with Savanna Kidz NFT's public sales, we created a facility where children can continue their studies in comfort, without suffering from the cold or rain.`,
          before: require('@/assets/img_2nd_project_before.png'),
          underConstruction: require('@/assets/img_2nd_project_under_construction.png'),
          after: require('@/assets/img_2nd_project_after.png'),
          mainImage: require('@/assets/img_2nd_project.png'),
          tweet: 'https://twitter.com/savannakidz/status/1593693606618857472?s=20&t=dW_6by-fazr71nMH5bNlDw',
          isLast: true
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.background{
  background-color: $light-green;
}

.accomp {
  background: #FFFFFF;
  border: 4px solid #000000;
  border-radius: 37px;

  @media (min-width: $tablet-min-width) {
    border: 8px solid #000000;
  }
}

.item-header-container {
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: $tablet-min-width) {
    flex-direction: row;
  }
}

.item-header-icon {
  @media (max-width: $tablet-min-width) {
    margin-top: 16px;
  }
}

.item-header-content {
}

.img-twitter{
  width: 48px;
  height: 48px;

  @media (max-width: $tablet-min-width) {
    width: 24px;
    height: 24px;
  }
}

.img-icon{
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $tablet-min-width) {
    margin-bottom: 16px;
    img{
      width: 120px;
    }
  }
}

.tweet {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.main-media-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-media{
  flex: 1;
  max-width: 512px;
}

.sub-image{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.pre-line{
  white-space: pre-line;
}

</style>
