<template>
  <div class='background'>

    <div class="common-section-container">
      <div class='purchase-box pd-24-pc pd-8 ml-16-mobile mr-16-mobile'>
        <h3 class='pd-8 font-size-36 font-size-24-mobile bold purchase-header mb-16'>
          Would you like to purchase an NFT and help improve the facilities at Karungu Village?
        </h3>

        <!-- white background card -->
        <div class='purchase-content pd-24-pc pd-8'>

          <!--- card header -->
          <div class='columns no-margin'>
            <div class='column'>
              <div class='font-size-24 bold'>
                Savanna Kidz NFT
              </div>

              <div class='purchase-about'>
                <a class='link font-size-16-mobile' href="#collection">
                  What is Savanna Kidz?
                </a>
              </div>
            </div>
          </div>

          <!--- card content -->
          <div class='columns pd-24-pc pd-8'>
            <div class='column is-7 no-padding'>
              <div class='sample-images'>
                <div class='sample-image'>
                  <img src="~@/assets/img_savannakidz_sample_1.png" alt="">
                </div>
                <div class='sample-image'>
                  <img src="~@/assets/img_savannakidz_sample_2.png" alt="">
                </div>
                <div class='sample-image' style='margin-right: 0;'>
                  <img src="~@/assets/img_savannakidz_sample_3.png" alt="">
                </div>
              </div>
            </div>

            <div class='column is-5 ml-16-pc'>
              <div class='purchase-right'>

                <div>
                  <div class='font-size-24-pc font-size-16-mobile bold mb-8'>
                    Price
                  </div>

                  <div class='font-size-36-pc font-size-24-mobile bold'>
                    0.05 ETH
                  </div>

                  <PrimaryButton
                    class='mt-32-pc mt-8-mobile'
                    :buttonText="'Buy an NFT'"
                    @click="open"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PrimaryButton from '@/components/PrimaryButton.vue'
import { useMinter } from '@/logic/useMinter'

export default {
  name: 'Purchase',
  components: {
    PrimaryButton,
  },
  setup() {
    const { open } = useMinter()
    return {
      open,
    }
  },
}
</script>

<style lang="scss" scoped>

.background{
  background-color: #AEEF97;
  display: flex;
  justify-content: center;
  align-items: center;
}

.purchase-box{
  background-color: $yellow;
  border: 4px solid #000000;
  border-radius: 16px;
}

.purchase-header{
  text-align: center;
}

.purchase-content{
  background-color: $white;
  border-radius: 24px;
}

.purchase-right{
  display: flex;
  flex: 1;
  flex-direction: column;
}

.purchase-about{
  display: flex;
  justify-content: flex-end;
}

.sample-images{
  display: flex;
  height: 100%;
  align-items: center;
}

.sample-image{
  flex: 1;
  flex-basis: 0;
  margin-right: 16px;
  @media (max-width: 768px) {
    margin-right: 8px;
  }
  img{
    width: 100%;
    height: auto;
  }
}

.common-section-container {
  @media (max-width: 768px) {
    padding: 0;
  }
}

</style>
