<template>
  <div class="container">
    <h1 class="title mt-6">
      Open Town Project admin panel
      <span class="tag is-warning is-light">{{ chainName }}</span>
    </h1>
    <div v-if="isLoading">Loading...</div>
    <div v-else-if="ethState.errorMessage" class="notification is-danger">
      {{ ethState.errorMessage }}
    </div>
    <div v-else-if="!ethState.address">
      <button
        @click="connect"
        class="button is-primary button is-rounded is-medium has-bold-border mt-4"
      >
        Connect wallet
      </button>
    </div>
    <div v-else-if="!ethState.isAdmin" class="notification is-warning">
      Access restricted to Open Town contract owner.
    </div>
    <div v-else>
      <div class="tabs">
        <ul>
          <li @click="skSelected = true" :class="skSelected ? 'is-active' : ''">
            <a>SavannaKidz</a>
          </li>
          <li
            @click="skSelected = false"
            :class="!skSelected ? 'is-active' : ''"
          >
            <a>OpenTown</a>
          </li>
        </ul>
      </div>
      <SavannaKidz v-if="skSelected" />
      <OpenTown v-else />
    </div>
  </div>
</template>

<script>
import "bulma/css/bulma.css";

import SavannaKidz from "../components/admin/SavannaKidz.vue";
import OpenTown from "../components/admin/OpenTown.vue";

import {
  state,
  checkProvider,
  checkAccess,
  connectWallet,
  removeEventListeners,
} from "../utils/ethereum";

export default {
  name: "Admin",
  components: { SavannaKidz, OpenTown },
  data: () => ({
    skSelected: true,
    isLoading: true,
    ethState: state,
    skOwnerCheckTokenId: null,
  }),
  computed: {
    chainName() {
      switch (this.ethState.chainId) {
        case 1:
          return "Ethereum Main Network (Mainnet)";
        case 4:
          return "Rinkeby Test Network";
        case 1337:
          return "Ganache";
        default:
          return "Unsupported chain.";
      }
    },
  },
  methods: {
    async connect() {
      this.isLoading = true;
      await connectWallet();
      await checkAccess();
      this.isLoading = false;
    },
  },
  async mounted() {
    await checkProvider();
    await checkAccess();
    this.isLoading = false;
  },
  unmounted() {
    removeEventListeners();
  },
};
</script>

<style lang="scss">
.is-disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
