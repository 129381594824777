import { computed } from "vue";
import { DOCS_URL, DOCS_EN_URL, DOCS_JA_URL } from "../config";

export const useDocuments = () => {
  const language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    window.navigator.browserLanguage;

  const docsUrl = computed(() => {
    if (language === "ja") {
      return DOCS_URL + DOCS_JA_URL;
    } else {
      return DOCS_URL + DOCS_EN_URL;
    }
  });

  return {
    docsUrl,
  };
};
