<template>
  <div :class="isLoading ? 'is-disabled' : ''">
    <div class="tags has-addons">
      <span class="tag is-medium">Admin balance</span>
      <span class="tag is-primary is-light is-medium">{{
        ethState.otBalanceOfAdmin
      }}</span>
    </div>
    <div class="field has-addons" :class="isLoading ? 'is-disabled' : ''">
      <div class="control is-expanded">
        <input
          class="input"
          type="number"
          min="0"
          v-model="amountToBurn"
          placeholder="Amount"
        />
      </div>
      <p class="control">
        <button
          class="button is-primary"
          @click="burn"
          :disabled="amountToBurn <= 0"
        >
          Burn
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import { state, getOTBalanceOfAdmin, burnOT } from "../../utils/ethereum";

export default {
  name: "OTBurn",
  data: () => ({
    isLoading: false,
    ethState: state,
    amountToBurn: 0,
  }),
  methods: {
    async fetchContractData() {
      this.isLoading = true;
      await getOTBalanceOfAdmin();
      this.isLoading = false;
    },
    async burn() {
      this.isLoading = true;
      await burnOT(this.amountToBurn);
      this.amountToBurn = 0;
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.fetchContractData();
  },
};
</script>
