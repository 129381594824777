<template>
  <div :class="isLoading ? 'is-disabled' : ''">
    <div class="tags has-addons">
      <span class="tag is-medium">ETH balance</span>
      <span class="tag is-medium is-primary is-light">{{
        ethState.skEthBalance
      }}</span>
    </div>
    <div class="tags has-addons">
      <span class="tag">Owner</span>
      <span class="tag is-info is-light">{{ ethState.skOwner }}</span>
    </div>
    <div class="tags has-addons">
      <span class="tag">FAM</span>
      <span class="tag is-primary is-light">{{
        ethState.skCurrentFAMAddress
      }}</span>
      <a @click="isAddressInput = true" class="ml-4 is-size-7">Update</a>
    </div>
    <div class="modal" :class="isAddressInput ? 'is-active' : ''">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Update FAM address</p>
          <button
            @click="closeAddressInput()"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">New address</label>
            <div class="control">
              <input
                v-model="newFAMAddress"
                class="input"
                type="text"
                placeholder="Ethereum address"
              />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button @click="setFAM" class="button is-primary">
            Update address
          </button>
          <button @click="closeAddressInput()" class="button">Cancel</button>
        </footer>
      </div>
    </div>
    <button
      @click="withdraw"
      class="button is-primary is-fullwidth"
      :disabled="ethState.skEthBalance <= 0"
    >
      Withdraw
    </button>
  </div>
</template>

<script>
import {
  state,
  getSKEthBalance,
  getCurrentFAMAddress,
  updateFAMAddress,
  withdrawFromSK,
} from "../../utils/ethereum";

export default {
  name: "SKWithdraw",
  data: () => ({
    isLoading: true,
    ethState: state,
    isAddressInput: false,
    newFAMAddress: null,
  }),
  methods: {
    async fetchContractData() {
      this.isLoading = true;
      await getSKEthBalance();
      await getCurrentFAMAddress();
      this.isLoading = false;
    },
    closeAddressInput() {
      this.newFAMAddress = null;
      this.isAddressInput = false;
    },
    async setFAM() {
      this.isAddressInput = false;
      this.isLoading = true;
      await updateFAMAddress(this.newFAMAddress);
      this.newFAMAddress = null;
      this.isLoading = false;
    },
    async withdraw() {
      await withdrawFromSK();
    },
  },
  async mounted() {
    await this.fetchContractData();
  },
};
</script>
