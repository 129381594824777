<template>
  <div :class="isLoading ? 'is-disabled' : ''">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <div>
            <div class="tags has-addons">
              <span class="tag">Presale</span>
              <span :class="`tag is-light is-${presaleClass}`">{{
                presaleStatus
              }}</span>
            </div>
            <button @click="togglePresale" class="button is-primary">
              Toggle presale
            </button>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div>
            <div class="tags has-addons">
              <span class="tag">Sale</span>
              <span :class="`tag is-light is-${saleClass}`">{{
                saleStatus
              }}</span>
            </div>
            <button @click="toggleSale" class="button is-primary">
              Toggle sale
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  state,
  getSaleStatus,
  getPresaleStatus,
  toggleSale,
  togglePresale,
} from "../../utils/ethereum";

export default {
  name: "SKSale",
  data: () => ({
    isLoading: true,
    ethState: state,
  }),
  computed: {
    saleStatus() {
      return this.ethState.skIsSaleActive ? "ON" : "OFF";
    },
    presaleStatus() {
      return this.ethState.skIsPresaleActive ? "ON" : "OFF";
    },
    saleClass() {
      return this.ethState.skIsSaleActive ? "success" : "danger";
    },
    presaleClass() {
      return this.ethState.skIsPresaleActive ? "success" : "danger";
    },
  },
  methods: {
    async fetchContractData() {
      this.isLoading = true;
      await getSaleStatus();
      await getPresaleStatus();
      this.isLoading = false;
    },
    async toggleSale() {
      this.isLoading = true;
      await toggleSale();
      this.isLoading = false;
    },
    async togglePresale() {
      this.isLoading = true;
      await togglePresale();
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.fetchContractData();
  },
};
</script>
